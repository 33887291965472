/* eslint-disable max-lines */
import { AttributeRequirement } from '@fallonsolutions/attribute'
import { dateConfig } from '@fallonsolutions/date'
import { ScheduleConflictType } from '@fallonsolutions/schedule'
import { compact, differenceWith, find, first, includes, last } from 'lodash-es'
import { DateTime, Duration } from 'luxon'
import {
  AfterHours,
  AvailabilityCategory,
  CustomerType,
  JobType,
  PriorityType,
  ProductivityType,
  ScheduleEventConfirmation,
  ScheduleEventFragment,
  ScheduleEventLockStatusFragment,
  ScheduleEventLockStatusType,
  ScheduleEventSource,
  ScheduleEventStatusType,
  ScheduleEventType,
  ScheduleFlexibility,
  ScheduleLaneDayFragment
} from '../api/generated-types'
import { decamelise } from '../common/utils'
import AnnualLeaveIcon from '../static/images/emoji/annualleave.png'
import CarServiceIcon from '../static/images/emoji/carservice.png'
import CashInIcon from '../static/images/emoji/cashin.png'
import CommercialIcon from '../static/images/emoji/commercial-icon.png'
import EarlyFinishIcon from '../static/images/emoji/earlyfinish.png'
import EmergencyIcon from '../static/images/emoji/emergency.png'
import IdleIcon from '../static/images/emoji/idle.png'
import LunchIcon from '../static/images/emoji/lunch.png'
import MeetingIcon from '../static/images/emoji/meeting.png'
import OfficeIcon from '../static/images/emoji/office.png'
import OnCallIcon from '../static/images/emoji/oncall.png'
import PickUpPartsIcon from '../static/images/emoji/pickupparts.png'
import priority1 from '../static/images/emoji/priority1.png'
import priority2 from '../static/images/emoji/priority2.png'
import priority3 from '../static/images/emoji/priority3.png'
import QuotingIcon from '../static/images/emoji/quoting.png'
import RideAlongIcon from '../static/images/emoji/ridealong.png'
import SickLeaveIcon from '../static/images/emoji/sickleave.png'
import StockOrderIcon from '../static/images/emoji/stockorder.png'
import TAFEIcon from '../static/images/emoji/tafe.png'
import ToBeAdvisedIcon from '../static/images/emoji/tobeadvised.png'
import TrainingIcon from '../static/images/emoji/training.png'
import TravelIcon from '../static/images/emoji/travel.png'
import UnavailableIcon from '../static/images/emoji/unavailable.png'
import WorkersCompensationIcon from '../static/images/emoji/workerscompensation.png'
import ElectricalSubcontractor from '../static/images/voltage-bolt.png'
import { colorForScheduleEventStatusType } from './schedule-event-status-type'
import { ScheduleDisplayConfiguration, ScheduleTheme } from './schedule-timeline/schedule-timeline-types'
import { ScheduleTokenType } from './schedule-token'

export interface ScheduleItemContact {
  type: CustomerType
  firstName?: string | undefined
  lastName?: string | undefined
  companyName?: string | undefined
}

export interface ScheduleItemActualTimes {
  start?: number | undefined
  end?: number | undefined
  dispatched?: number | undefined
  enRoute?: number | undefined
  onSite?: number | undefined
  inProgress?: number | undefined
  completed?: number | undefined
}

export interface ScheduleItem {
  id: string
  group: number
  laneId: string
  canMove: boolean
  canResize: boolean
  isCut: boolean
  isCopy: boolean
  title: string
  contact?: ScheduleItemContact
  lockStatus?: ScheduleEventLockStatusFragment
  status: ScheduleEventStatusType
  jobType?: JobType
  isLegacy: boolean
  emoji?: string | undefined
  emojiImage?: string | undefined
  subtitle?: string | undefined
  description?: string | undefined
  type: ScheduleEventType
  start_time: number
  end_time: number
  travel_time: number
  travel_distance: number
  actualTimes?: ScheduleItemActualTimes | undefined
  actualStart?: number | undefined
  actualEnd?: number | undefined
  post_travel_time?: number | undefined
  post_travel_distance?: number | undefined
  duration: number
  suburb: string
  suburbShort: string
  titleColor: string
  foregroundColor: string
  itemColor: string
  selectedBgColor: string
  bgColor: string
  itemProps?: any | undefined
  loading: boolean
  deleting: boolean
  error?: any | undefined
  conflicts: ScheduleConflictType[]
  tokens?: ScheduleTokenType[]
}

export interface LocalScheduleEvent {
  event: ScheduleEventFragment
  item: ScheduleItem
}

export interface LocalEventChange {
  eventId: string
  start?: number
  end?: number
  group?: number
  laneId?: string
  loading: boolean
  error?: Error | undefined
  deleting?: boolean | undefined
  deleted?: boolean
}

export interface LocalAdditionEventChange extends LocalEventChange {
  type: ScheduleEventType
  requestId: string
  group: number
  appointmentId?: string
  confirmation?: ScheduleEventConfirmation
  ttl?: number
}

interface ScheduleEventAdapterOpts {
  canEdit: boolean
  canMove: boolean
  isCut: boolean
  isCopy: boolean
  laneDays?: ScheduleLaneDayFragment[]
  showAbortedEvents: boolean
}

const stripLowercaseVowelsOutIfTooLong = (text: string | null | undefined, maxTextWidth = 10) => {
  const regex = /[bcdfghjklmnpqrstvwxzBCDFGHJKLMNPQRSTVWXZ][aeiouy]+[bcdfghjklmnpqrstvwxz]/
  let modifiedText = text
  while (modifiedText && modifiedText.length > maxTextWidth && regex.test(modifiedText)) {
    modifiedText = modifiedText.replace(
      regex,
      (foundOccurrence: string) => foundOccurrence[0] + foundOccurrence.substr(-1)
    )
  }
  if (modifiedText && modifiedText.length > maxTextWidth + 3) {
    modifiedText = modifiedText.substr(0, maxTextWidth) + '...'
  }
  return modifiedText
}

const hasSkillsConflict = (
  appointment: AttributeRequirement[],
  laneDay: AttributeRequirement[]
): ScheduleConflictType | undefined => {
  const hasAppointmentRequirements = appointment.length > 0
  //legacy skills conflict
  const unmetRequirements = differenceWith(appointment, laneDay, (a, b) => a.attributeId === b.attributeId)

  if (hasAppointmentRequirements && unmetRequirements.length > 0) {
    return ScheduleConflictType.Skills
  }
  return undefined
}

//transform events and any local modifications including deletes
/* eslint-disable-next-line complexity */
export const scheduleEventAdapter = (
  event: ScheduleEventFragment,
  group: number,
  eventChanges: LocalEventChange[],
  displayConfiguration: ScheduleDisplayConfiguration,
  opts: ScheduleEventAdapterOpts
): LocalScheduleEvent | undefined => {
  const { isCut, isCopy, laneDays, showAbortedEvents } = opts
  const travelTime = event.travelDuration ?? 0
  const travelDistance = event.travelDistance ?? 0
  const start = DateTime.fromISO(event.scheduled.from)
  const end = DateTime.fromISO(event.scheduled.to)

  const status = event?.status?.status
  const bgColor = backgroundColorForEventType({
    type: event.type,
    status,
    alpha: 1.0,
    theme: displayConfiguration.theme
  })
  const itemColor = foregroundColorForEventType({
    type: event.type,
    status,
    alpha: 1.0,
    theme: displayConfiguration.theme
  })
  const fgColor = foregroundColorForEventType({
    type: event.type,
    status,
    alpha: 0.8,
    theme: displayConfiguration.theme
  })

  const eventChange = eventChanges.find((e) => e.eventId === event.id)
  // const canEdit = opts.canEdit && event.source === ScheduleEventSource.Platform
  const canMove =
    opts.canMove &&
    event.source === ScheduleEventSource.Platform &&
    event.lockStatus?.status !== ScheduleEventLockStatusType.Locked

  if (eventChange && eventChange.deleted === true) {
    return undefined
  }

  if (event.status?.status === ScheduleEventStatusType.Aborted && !showAbortedEvents) {
    return undefined
  }

  const deleting = !!eventChange?.deleting

  const start_time = eventChange?.start ?? start.valueOf()
  const end_time = eventChange?.end ?? end.valueOf()
  const duration = (end_time - start_time) / 1000 / 60

  //todo: safe to remove post migrations as conflicts calculated and persisted
  const conflicts: ScheduleConflictType[] = []
  //    cloneDeep(event.conflicts?.filter((c) => c !== ScheduleConflictType.Assignment)) ?? []

  if (event.bookingWindow?.from && !conflicts.includes(ScheduleConflictType.BookingWindow)) {
    const windowFrom = DateTime.fromISO(event.bookingWindow.from).valueOf()
    const windowTo = DateTime.fromISO(event.bookingWindow.to).valueOf()
    if (!(start_time >= windowFrom && start_time <= windowTo)) {
      conflicts.push(ScheduleConflictType.BookingWindow)
    }
  }

  const laneDay = find(laneDays, (ld: ScheduleLaneDayFragment) => ld.laneId === event.laneId)
  //legacy requirements
  const skillConflict = hasSkillsConflict(event.appointmentRequirements?.attributes ?? [], laneDay?.attributes ?? [])
  if (skillConflict && !conflicts.includes(ScheduleConflictType.Skills)) {
    conflicts.push(ScheduleConflictType.Skills)
  }

  // if (event.conflict) {
  //   conflict = item.conflict.concat(event.conflicts)
  // }
  const actualTime = getActualTime(event)
  const actualTimes = getActualTimes(event)

  const firstName = event.customerData?.mainContact?.firstName ?? undefined
  const lastName = event.customerData?.mainContact?.lastName ?? undefined
  const companyName = event.customerData?.company?.name ?? undefined
  const customerType = event.customerData?.type ?? CustomerType.None
  const contact: ScheduleItemContact = { firstName, lastName, companyName, type: customerType }
  const vaccinatedRequirement = !!event.appointmentRequirements?.attributes?.find(
    (a) => a.attributeId === 'VaccinatedCovid19'
  )

  const getPriorityToken = (priority: PriorityType): ScheduleTokenType[] => {
    switch (priority) {
      case PriorityType.P1:
        return [ScheduleTokenType.Priority]
      case PriorityType.P2:
        return [ScheduleTokenType.Priority2]
      default:
        return [] as ScheduleTokenType[]
    }
  }

  return {
    event,
    item: {
      id: event.id,
      group: eventChange?.group ?? group,
      laneId: eventChange?.laneId ?? event.laneId,
      isCut,
      isCopy,
      status: event.status?.status ?? ScheduleEventStatusType.WaitingForAllocation,
      jobType: event.jobType ?? undefined,
      canMove: canMove,
      canResize: canMove,
      isLegacy: event.source !== ScheduleEventSource.Platform,
      title: titleForEvent(event, displayConfiguration),
      contact,
      emoji: emojiForEvent(event.type),
      emojiImage: emojiImageForEvent(event.type),
      subtitle: subtitleForEvent(event),
      type: event.type,
      start_time,
      end_time,
      actualTimes,
      actualStart: actualTime.from ? DateTime.fromISO(actualTime.from).toMillis() : undefined,
      actualEnd: actualTime.to ? DateTime.fromISO(actualTime.to).toMillis() : undefined,
      travel_time: travelTime,
      travel_distance: travelDistance,
      duration,
      description: descriptionForEvent(event),
      suburb: event.location?.suburb ?? 'No location',
      suburbShort: stripLowercaseVowelsOutIfTooLong(event.location?.suburb) || 'No loc',
      titleColor: itemColor,
      foregroundColor: fgColor,
      itemColor: colorForEventType(event.type, event.status?.status),
      selectedBgColor: bgColor,
      lockStatus: event.lockStatus ?? undefined,
      bgColor: bgColor,
      // url: type === ScheduleEventType.Appointment ? `/jobs/${appointment.job?.id}` : null,
      itemProps: {
        className: 'fs-calendar__appointment'
      },
      loading: (eventChange?.loading ?? false) && !deleting,
      deleting,
      error: eventChange?.error ?? undefined,
      conflicts,
      tokens: [
        ...(event.lockStatus?.status === ScheduleEventLockStatusType.Locked ? [ScheduleTokenType.Locked] : []),
        ...([AfterHours.Booked, AfterHours.Callout].includes(event.afterHours ?? AfterHours.None)
          ? [ScheduleTokenType.AfterHours]
          : []),
        ...(event?.appointmentRequirements?.priority?.required
          ? getPriorityToken(event?.appointmentRequirements?.priority?.required)
          : []),
        ...(vaccinatedRequirement ? [ScheduleTokenType.Vaccinated] : []),
        ...(includes(
          [ScheduleFlexibility.Flexible, ScheduleFlexibility.VeryFlexible],
          event?.appointmentRequirements?.flexibility?.schedule
        )
          ? [ScheduleTokenType.Flexible]
          : [])
      ]
    }
  }
}

/* eslint-disable-next-line complexity */
export const localAdditionAdapter = (
  eventChange: LocalAdditionEventChange,
  displayConfiguration: ScheduleDisplayConfiguration
): LocalScheduleEvent => {
  const travelTime = 0
  const travelDistance = 0

  if (!eventChange.start || !eventChange.end) {
    throw Error('missing start and end times')
  }

  const start = DateTime.fromMillis(eventChange.start, { zone: dateConfig.defaultTimezone })
  const end = DateTime.fromMillis(eventChange.end, { zone: dateConfig.defaultTimezone })
  const from = eventChange.start ? start.toISO() : ''
  const to = eventChange.end ? end.toISO() : ''

  const status = ScheduleEventStatusType.WaitingForAllocation //eventChange.status?.status
  const bgColor = backgroundColorForEventType({
    type: eventChange.type,
    status,
    alpha: 1.0,
    theme: displayConfiguration.theme
  })
  const itemColor = foregroundColorForEventType({
    type: eventChange.type,
    status,
    alpha: 1.0,
    theme: displayConfiguration.theme
  })
  const fgColor = foregroundColorForEventType({
    type: eventChange.type,
    status,
    alpha: 0.8,
    theme: displayConfiguration.theme
  })

  const canEdit = false
  const created = DateTime.now().setZone(dateConfig.defaultTimezone).toISO()
  const scheduled = { from, to }

  // jobNumber, jobCategory, jobType
  const event: ScheduleEventFragment = {
    id: eventChange.eventId,
    laneId: eventChange.laneId as string,
    confirmation: eventChange.confirmation ?? ScheduleEventConfirmation.Tentative,
    name: 'Appointment',
    productivity: ProductivityType.Productive,
    source: ScheduleEventSource.Platform,
    created,
    updated: created,
    scheduled,
    availableStatus: {},
    ttl: eventChange.ttl,
    type: eventChange.type
  }

  const start_time = eventChange?.start ?? start.valueOf()
  const end_time = eventChange?.end ?? end.valueOf()
  const duration = (end_time - start_time) / 1000 / 60

  const conflicts = [] as ScheduleConflictType[]
  if (event.bookingWindow?.from) {
    const windowFrom = DateTime.fromISO(event.bookingWindow.from).valueOf()
    const windowTo = DateTime.fromISO(event.bookingWindow.to).valueOf()
    if (!(start_time >= windowFrom && start_time <= windowTo)) {
      conflicts.push(ScheduleConflictType.BookingWindow)
    }
  }

  const actualTime = getActualTime(event)
  const actualTimes = getActualTimes(event)

  const firstName = event.customerData?.mainContact?.firstName ?? undefined
  const lastName = event.customerData?.mainContact?.lastName ?? undefined
  const companyName = event.customerData?.company?.name ?? undefined
  const customerType = event.customerData?.type ?? CustomerType.None
  const contact: ScheduleItemContact = { firstName, lastName, companyName, type: customerType }
  const vaccinatedRequirement = !!event.appointmentRequirements?.attributes?.find(
    (a) => a.attributeId === 'VaccinatedCovid19'
  )

  return {
    event,
    item: {
      id: event.id,
      group: eventChange.group,
      laneId: eventChange?.laneId ?? event.laneId,
      status: event.status?.status ?? ScheduleEventStatusType.WaitingForAllocation,
      jobType: event.jobType ?? undefined,
      canMove: canEdit,
      canResize: canEdit,
      isCut: false,
      isCopy: false,
      isLegacy: event.source !== ScheduleEventSource.Platform,
      title: titleForEvent(event, displayConfiguration),
      contact,
      emoji: emojiForEvent(event.type),
      emojiImage: emojiImageForEvent(event.type),
      subtitle: subtitleForEvent(event),
      type: event.type,
      start_time,
      end_time,
      actualTimes,
      actualStart: actualTime.from ? DateTime.fromISO(actualTime.from).toMillis() : undefined,
      actualEnd: actualTime.to ? DateTime.fromISO(actualTime.to).toMillis() : undefined,
      travel_time: travelTime,
      travel_distance: travelDistance,
      duration,
      description: descriptionForEvent(event),
      suburb: event.location?.suburb ?? 'No location',
      suburbShort: stripLowercaseVowelsOutIfTooLong(event.location?.suburb) || 'No loc',
      titleColor: itemColor,
      foregroundColor: fgColor,
      itemColor: colorForEventType(event.type, event.status?.status),
      selectedBgColor: bgColor,
      bgColor: bgColor,
      itemProps: {
        className: 'fs-calendar__appointment'
      },
      loading: eventChange?.loading ?? false,
      deleting: false,
      error: eventChange?.error ?? undefined,
      conflicts,
      tokens: [
        ...(event.lockStatus?.status === ScheduleEventLockStatusType.Locked ? [ScheduleTokenType.Locked] : []),
        ...(event.appointmentPriority === PriorityType.P1 ? [ScheduleTokenType.Priority] : []),
        ...(event.appointmentPriority === PriorityType.P2 ? [ScheduleTokenType.Priority2] : []),
        ...(vaccinatedRequirement ? [ScheduleTokenType.Vaccinated] : []),
        ...(includes(
          [ScheduleFlexibility.Flexible, ScheduleFlexibility.VeryFlexible],
          event?.appointmentRequirements?.flexibility?.schedule
        )
          ? [ScheduleTokenType.Flexible]
          : [])
      ]
    }
  }
}

interface ActualTimeWindow {
  from?: string | undefined
  to?: string | undefined
}

const getActualTime = (event: ScheduleEventFragment): ActualTimeWindow => {
  const eventHistory = compact(event.statusHistory) ?? []

  const timesheetEntries = compact(event.timesheetEntries ?? [])
  const timesheetEntryFrom = first(timesheetEntries)?.time.from
  const timesheetEntryTo = last(timesheetEntries)?.time.to

  const timesheetEntryTime =
    timesheetEntryFrom && timesheetEntryTo ? { from: timesheetEntryFrom, to: timesheetEntryTo } : undefined

  const onSite =
    eventHistory.find((e) => e.status === ScheduleEventStatusType.OnSite)?.date ??
    eventHistory.find((e) => e.status === ScheduleEventStatusType.InProgress)?.date
  const completed = eventHistory.find((e) => e.status === ScheduleEventStatusType.Completed)?.date
  const { from, to } = event.scheduled

  if (timesheetEntryTime) {
    return { from: timesheetEntryTime.from, to: timesheetEntryTime.to }
  } else if (onSite && completed) {
    if (DateTime.fromISO(onSite).toMillis() > DateTime.fromISO(completed).toMillis()) {
      console.log('from date is greater than to date, defaulting to scheduleEvent.scheduled values')
      return { from, to }
    } else {
      return { from: onSite, to: completed }
    }
  } else if (onSite) {
    return { from: onSite }
  } else if (completed) {
    return { from, to: completed }
  } else {
    return {}
  }
}

const getActualTimes = (event: ScheduleEventFragment): ScheduleItemActualTimes => {
  const statusHistory = compact(event.statusHistory ?? [])
  const dispatched = statusHistory.find((s) => s.status === ScheduleEventStatusType.Dispatched)?.date
  const enRoute = statusHistory.find((s) => s.status === ScheduleEventStatusType.EnRoute)?.date
  const onSite = statusHistory.find((s) => s.status === ScheduleEventStatusType.OnSite)?.date
  const inProgress = statusHistory.find((s) => s.status === ScheduleEventStatusType.InProgress)?.date
  const completed = statusHistory.find((s) => s.status === ScheduleEventStatusType.Completed)?.date
  const start = dispatched ?? enRoute ?? onSite ?? inProgress
  const end = completed
  return {
    ...(start && { start: DateTime.fromISO(start).toMillis() }),
    ...(end && { end: DateTime.fromISO(end).toMillis() }),
    ...(dispatched && { dispatched: DateTime.fromISO(dispatched).toMillis() }),
    ...(enRoute && { enRoute: DateTime.fromISO(enRoute).toMillis() }),
    ...(onSite && { onSite: DateTime.fromISO(onSite).toMillis() }),
    ...(inProgress && { inProgress: DateTime.fromISO(inProgress).toMillis() }),
    ...(completed && { completed: DateTime.fromISO(completed).toMillis() })
  }
}

const colorForEventType = (type: ScheduleEventType, status?: ScheduleEventStatusType, alpha?: number): string => {
  switch (type) {
    case ScheduleEventType.Appointment:
      return colorForScheduleEventStatusType(status)
    case ScheduleEventType.Lunch:
    case ScheduleEventType.Travel:
      return `rgba(34,254,253,${alpha ?? 1.0})`
    case ScheduleEventType.Office:
      return `rgba(253,130,37,${alpha ?? 1.0})`
    case ScheduleEventType.Training:
      return `rgba(253,27,253,${alpha ?? 1.0})`
    default:
      return `rgba(34,254,253,${alpha ?? 1.0})`
  }
}

interface ColorForEventTypeProps {
  type: ScheduleEventType
  status?: ScheduleEventStatusType
  alpha?: number
  theme?: ScheduleTheme
}

export const foregroundColorForEventType = (props: ColorForEventTypeProps): string => {
  const { alpha, theme, status } = props
  const alphaVal = status === ScheduleEventStatusType.Completed ? (alpha ?? 1.0) - 0.2 : (alpha ?? 1.0)
  switch (theme) {
    case ScheduleTheme.Dark:
      return `rgba(154, 158, 185, ${alphaVal})`
    case ScheduleTheme.Classic:
      return `rgba(17, 17, 17, ${alphaVal})`
    case ScheduleTheme.Light:
    default:
      return `rgba(82, 91, 114, ${alphaVal})`
  }
}

export const backgroundColorForEventType = (props: ColorForEventTypeProps): string => {
  const { type, theme, status } = props
  if (theme === ScheduleTheme.Classic) {
    return classicBackgroundColorForEventType(props)
  }

  switch (type) {
    case ScheduleEventType.Appointment:
      switch (theme) {
        case ScheduleTheme.Light:
          switch (status) {
            case ScheduleEventStatusType.Completed:
              return 'rgba(252,252,255,0.6)'
            case ScheduleEventStatusType.Cancelled:
            case ScheduleEventStatusType.Aborted:
              return '#FEFFE2'
            default:
              return '#ffffff'
          }
        case ScheduleTheme.Dark:
          return '#2D3044'
        default:
          return '#ffffff'
      }
    case ScheduleEventType.Priority1:
    case ScheduleEventType.Priority2:
    case ScheduleEventType.Priority3:
      return 'rgba(214,219,235, 0.2)'
    case ScheduleEventType.Available:
      return 'transparent'
    case ScheduleEventType.Lunch: // return `rgba(219,255,255, ${alpha ?? 0.2})`
    case ScheduleEventType.AnnualLeave:
    case ScheduleEventType.CashIn:
    case ScheduleEventType.NotAvailable:
    case ScheduleEventType.Office: // return `rgba(255,241,228,${alpha ?? 1.0})`
    case ScheduleEventType.Training: // return `rgba(255,236,255,${alpha ?? 1.0})`
    case ScheduleEventType.Meeting:
    case ScheduleEventType.CarService:
    case ScheduleEventType.Emergency:
    case ScheduleEventType.Idle:
    case ScheduleEventType.ToBeAdvised:
    case ScheduleEventType.OnCall:
    case ScheduleEventType.PickUpParts:
    case ScheduleEventType.Quoting:
    case ScheduleEventType.SickLeave:
    case ScheduleEventType.StockOrder:
    case ScheduleEventType.Travel:
    default:
      switch (theme) {
        case ScheduleTheme.Dark:
          return `rgba(45,48,68,0.5)`
        case ScheduleTheme.Light:
        default:
          return `rgba(214,219,235, 0.5)`
      }
  }
}

export const classicBackgroundColorForEventType = (props: ColorForEventTypeProps): string => {
  const { type, status } = props
  switch (type) {
    case ScheduleEventType.Appointment:
      switch (status) {
        case ScheduleEventStatusType.Cancelled:
          return '#6699E4'

        case ScheduleEventStatusType.Completed:
          return '#AED8E5'
        case ScheduleEventStatusType.Acknowledged:
          return '#F890ED'
        case ScheduleEventStatusType.EnRoute:
          return '#467CE5'
        case ScheduleEventStatusType.InProgress:
          return '#63DD94'
        case ScheduleEventStatusType.OnSite:
          return '#84E6EC'
        case ScheduleEventStatusType.Ready:
          return '#AC5FCA'
        case ScheduleEventStatusType.Dispatched:
          return '#BD8AF1'
        case ScheduleEventStatusType.Pending:
          return '#FFB82F'
        case ScheduleEventStatusType.WaitingForAllocation:
          return '#C4C4C4' //'#CAE434'
        default:
          return '#CAE434'
      }
    case ScheduleEventType.Available:
      return 'transparent'
    case ScheduleEventType.Lunch:
    case ScheduleEventType.PickUpParts:
    case ScheduleEventType.Idle:
    case ScheduleEventType.Travel:
    case ScheduleEventType.StockOrder:
      return '#2DFEFE'
    case ScheduleEventType.CashIn:
    case ScheduleEventType.Office:
    case ScheduleEventType.Quoting:
      return '#FD8023'
    case ScheduleEventType.Training:
    case ScheduleEventType.Meeting:
    case ScheduleEventType.CarService:
      return '#FC28FC'
    case ScheduleEventType.ToBeAdvised:
      return '#FB1682'
    case ScheduleEventType.AnnualLeave:
      return '#29FC2E'
    case ScheduleEventType.ElectricalSubcontractor:
    case ScheduleEventType.PlumbingSubcontractor:
    case ScheduleEventType.HVACSubcontractor:
      return '#AFADB1'
    case ScheduleEventType.NotAvailable:
      return '#FD82BF'
    case ScheduleEventType.SickLeave:
      return '#F9FC47'
    case ScheduleEventType.Emergency:
      return '#2AFD85'
    case ScheduleEventType.OnCall:
      return '#FB8282'
    default:
      return ''
  }
}

export const emojiForEvent = (type: ScheduleEventType): string => {
  switch (type) {
    case ScheduleEventType.Appointment:
      return ''
    case ScheduleEventType.AnnualLeave:
      return '🏝'
    case ScheduleEventType.CarService:
      return '🔧'
    case ScheduleEventType.CashIn:
      return '💵'
    case ScheduleEventType.Emergency:
      return '🚨'
    case ScheduleEventType.EarlyFinish:
      return '🏡'
    case ScheduleEventType.Idle:
      return '⏳'
    case ScheduleEventType.Lunch:
      return '🍔'
    case ScheduleEventType.Meeting:
      return '📅'
    case ScheduleEventType.NotAvailable:
      return '🔒'
    case ScheduleEventType.Office:
      return '📥'
    case ScheduleEventType.OnCall:
      return '📲'
    case ScheduleEventType.PickUpParts:
      return '📦'
    case ScheduleEventType.Quoting:
      return '📝'
    case ScheduleEventType.SickLeave:
      return '🤢'
    case ScheduleEventType.StockOrder:
      return '🛒'
    case ScheduleEventType.TAFE:
      return '📚'
    case ScheduleEventType.Training:
      return '🎓'
    case ScheduleEventType.Travel:
      return '🚐'
    case ScheduleEventType.ToBeAdvised:
      return '🤔'
    case ScheduleEventType.WorkersCompensation:
      return '🤕'
    case ScheduleEventType.Commercial:
    case ScheduleEventType.ElectricalSubcontractor:
    case ScheduleEventType.PlumbingSubcontractor:
    case ScheduleEventType.HVACSubcontractor:
      return '👷‍♂️'
    case ScheduleEventType.RideAlong:
      return '🛵'
    case ScheduleEventType.Priority1:
      return '&#8986'
    case ScheduleEventType.Priority2:
      return '&#8986'
    case ScheduleEventType.Priority3:
      return '&#8986'
    default:
      return ''
  }
}
export const emojiForAvailabilityCategory = (category: AvailabilityCategory): string => {
  switch (category) {
    case AvailabilityCategory.Priority1:
      return priority1
    case AvailabilityCategory.Priority2:
      return priority2
    case AvailabilityCategory.Priority3:
      return priority3
    default:
      return ''
  }
}

export const emojiImageForAvailabilityCategory = (category: AvailabilityCategory): string | undefined => {
  switch (category) {
    case AvailabilityCategory.Priority1:
      return priority1
    case AvailabilityCategory.Priority2:
      return priority2
    case AvailabilityCategory.Priority3:
      return priority3
    default:
      return undefined
  }
}
export const emojiImageForEvent = (type: ScheduleEventType): string | undefined => {
  switch (type) {
    case ScheduleEventType.Appointment:
      return undefined
    case ScheduleEventType.AnnualLeave:
      return AnnualLeaveIcon
    case ScheduleEventType.Commercial:
      return CommercialIcon
    case ScheduleEventType.ElectricalSubcontractor:
      return ElectricalSubcontractor
    case ScheduleEventType.PlumbingSubcontractor:
    case ScheduleEventType.HVACSubcontractor:
    case ScheduleEventType.CarService:
      return CarServiceIcon
    case ScheduleEventType.CashIn:
      return CashInIcon
    case ScheduleEventType.Emergency:
      return EmergencyIcon
    case ScheduleEventType.EarlyFinish:
      return EarlyFinishIcon
    case ScheduleEventType.Idle:
      return IdleIcon
    case ScheduleEventType.Lunch:
      return LunchIcon
    case ScheduleEventType.Meeting:
      return MeetingIcon
    case ScheduleEventType.NotAvailable:
      return UnavailableIcon
    case ScheduleEventType.Office:
      return OfficeIcon
    case ScheduleEventType.OnCall:
      return OnCallIcon
    case ScheduleEventType.PickUpParts:
      return PickUpPartsIcon
    case ScheduleEventType.Quoting:
      return QuotingIcon
    case ScheduleEventType.SickLeave:
      return SickLeaveIcon
    case ScheduleEventType.StockOrder:
      return StockOrderIcon
    case ScheduleEventType.TAFE:
      return TAFEIcon
    case ScheduleEventType.Training:
      return TrainingIcon
    case ScheduleEventType.Travel:
      return TravelIcon
    case ScheduleEventType.ToBeAdvised:
      return ToBeAdvisedIcon
    case ScheduleEventType.WorkersCompensation:
      return WorkersCompensationIcon
    case ScheduleEventType.RideAlong:
      return RideAlongIcon
    case ScheduleEventType.Priority1:
      return priority1
    case ScheduleEventType.Priority2:
      return priority2
    case ScheduleEventType.Priority3:
      return priority3
    default:
      return undefined
  }
}

export const titleForEvent = (event: ScheduleEventFragment, displayConfiguration?: ScheduleDisplayConfiguration) => {
  const { type, name } = event
  const textLabel = displayConfiguration?.theme === ScheduleTheme.Classic ? ` ${decamelise(event.type)}` : ''
  switch (type) {
    case ScheduleEventType.Appointment:
      return event.name === 'Appointment' ? (event.reference ?? 'TBA') : name
    case ScheduleEventType.Lunch:
      return 'Lunch'
    case ScheduleEventType.Training:
      return 'Training'
    case ScheduleEventType.WorkersCompensation:
      return 'Workers compensation'
    case ScheduleEventType.TAFE:
      return 'TAFE'
    case ScheduleEventType.ElectricalSubcontractor:
      return 'Electrical Subcontractor'
    case ScheduleEventType.PlumbingSubcontractor:
      return 'Plumbing Subcontractor'
    case ScheduleEventType.HVACSubcontractor:
      return 'HVAC Subcontractor'
    case ScheduleEventType.Priority1:
      return `Priority 1`
    case ScheduleEventType.Priority2:
      return `Priority 2`
    case ScheduleEventType.Priority3:
      return `Priority 3`
    case ScheduleEventType.ToBeAdvised:
      return event?.createdBy?.contactDetail?.fullName ?? 'TBA'
    case ScheduleEventType.NotAvailable:
      switch (event.reference) {
        case 'HWSConnection':
          return '🚰 HWS'
        case 'DSROnly':
          return ' DSR Only'
        default:
          return ''
      }
    case ScheduleEventType.Available: {
      if (!event.duration) {
        return ''
      }
      const duration = Duration.fromObject({ minutes: event.duration })
      if (duration.as('hours') >= 1) {
        return duration.toFormat(`h'h' m'm'`)
      } else {
        return duration.toFormat(`m'm'`)
      }
    }
    default:
      return textLabel
  }
}

export const descriptionForEvent = (event: ScheduleEventFragment): string | undefined => {
  if (event.type === ScheduleEventType.Appointment) {
    // const includeType = event.jobType && event.jobType !== JobType.Service
    const jobAppointmentCount = event.jobAppointmentCount ?? 1
    const components: string[] = [
      ...(event.reference ? [event.reference ?? ''] : []),
      // ...(includeType ? [event.jobType ?? ''] : []),
      ...(jobAppointmentCount > 1
        ? compact([event.jobType, `(${event.appointmentNumber}/${event.jobAppointmentCount})`])
        : [event.jobType ?? ''])
    ]
    return components.length > 0 ? components.join(' ') : ' '
  } else {
    return undefined
  }
}

export const subtitleForEvent = (event: ScheduleEventFragment): string | undefined => {
  switch (event.type) {
    case ScheduleEventType.Appointment: {
      const startHour = DateTime.fromISO(event?.bookingWindow?.from, { zone: dateConfig.defaultTimezone }).toFormat('h')
      const end = DateTime.fromISO(event?.bookingWindow?.to, { zone: dateConfig.defaultTimezone }).toFormat('ha')
      return event?.bookingWindow ? `${startHour}-${end.toLowerCase()}` : ''
    }
    case ScheduleEventType.ToBeAdvised:
      return event?.createdBy?.contactDetail?.fullName ?? ''
    default:
      return undefined
  }
}
/* eslint-enable max-lines */
