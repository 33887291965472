import { useMutation } from '@apollo/client'
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { uniq } from 'lodash-es'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import {
  ContactCustomerRole,
  CustomerContactContactFragment,
  CustomerFragment,
  SetContactCustomerRolesDocument
} from '../api/generated-types'
import { ContactCard } from '../contacts/contact-card'
import { CreateContactForm } from '../contacts/create-contact-form'
import { EditContactForm } from '../contacts/edit-contact-form'
import { UpdateContactDataQualityForm } from '../contacts/update-contact-data-quality-form'
import { AddCustomerContactForm } from './add-customer-contact-form'
import { ContactCustomerRoleBadge } from './customer-contact-role-badge'
import { RemoveCustomerContactForm } from './remove-customer-contact-form'

export interface CustomerContactsViewProps {
  customer: CustomerFragment
}

export const CustomerContactsView = ({ customer }: CustomerContactsViewProps) => {
  const contacts = customer.contacts ?? []

  const navigate = useNavigate()

  const [isContactDetailsMenuOpen, setIsContactDetailsMenuOpen] = useState<{
    [contactId: string]: boolean
  }>({})

  const [isContactDetailsModalVisible, setIsContactDetailsModalVisible] = useState(false)
  const [isCreateContactModalVisible, setIsCreateContactModalVisible] = useState(false)
  const [isAddCustomerContactModalVisible, setIsAddCustomerContactModalVisible] = useState(false)
  const [isRemoveContactModalVisible, setIsRemoveContactModalVisible] = useState(false)
  const [isUpdateContactDataQualityModalVisible, setIsUpdateContactDataQualityModalVisible] = useState(false)
  const [selectedContact, setSelectedContact] = useState<CustomerContactContactFragment | undefined>(undefined)

  const [setContactCustomerRoles, { loading }] = useMutation(SetContactCustomerRolesDocument, {
    refetchQueries: ['GetCustomer', 'GetContact'],
    awaitRefetchQueries: true
  })

  const handleContactCustomerRoleChange = async (
    contact: CustomerContactContactFragment,
    role: ContactCustomerRole
  ) => {
    const roles = uniq(contact.roles.concat(role))
    await setContactCustomerRoles({
      variables: {
        input: {
          contactId: contact.contactId,
          customerId: customer.id,
          roles
        }
      }
    })
    setIsContactDetailsMenuOpen({
      ...isContactDetailsMenuOpen,
      [contact.contactId]: false
    })
  }

  const makeContactContextMenu = (contact: CustomerContactContactFragment) => {
    const roles = contact.roles ?? []
    const isBilling = roles.includes(ContactCustomerRole.Billing)
    const isPrimary = roles.includes(ContactCustomerRole.Primary)
    const canRemove = contacts.length > 1
    return [
      {
        id: 0,
        items: [
          {
            name: 'Go to contact',
            icon: 'user',
            onClick: () => {
              setIsContactDetailsMenuOpen({
                ...isContactDetailsMenuOpen,
                [contact.contactId]: false
              })
              navigate(`/contacts/${contact.contactId}`)
              setIsContactDetailsModalVisible(true)
            }
          },
          {
            name: 'Edit contact',
            icon: 'documentEdit',
            onClick: () => {
              setIsContactDetailsMenuOpen({
                ...isContactDetailsMenuOpen,
                [contact.contactId]: false
              })
              setSelectedContact(contact)
              setIsContactDetailsModalVisible(true)
            }
          },
          {
            name: 'Edit contact data quality',
            icon: 'check',
            onClick: () => {
              setIsContactDetailsMenuOpen({
                ...isContactDetailsMenuOpen,
                [contact.contactId]: false
              })
              setSelectedContact(contact)
              setIsUpdateContactDataQualityModalVisible(true)
            }
          },
          ...(!isBilling
            ? [
                {
                  name: `Set as billing contact`,
                  icon: iconForContactCustomerRole(ContactCustomerRole.Billing),
                  disabled: loading,
                  onClick: () => {
                    handleContactCustomerRoleChange(contact, ContactCustomerRole.Billing)
                  }
                }
              ]
            : []),
          ...(!isPrimary
            ? [
                {
                  name: `Set as primary contact`,
                  icon: iconForContactCustomerRole(ContactCustomerRole.Primary),
                  disabled: loading,
                  onClick: () => {
                    handleContactCustomerRoleChange(contact, ContactCustomerRole.Primary)
                  }
                }
              ]
            : []),
          ...(canRemove
            ? [
                {
                  isSeparator: true
                },
                {
                  name: 'Remove',
                  icon: 'error',
                  onClick: () => {
                    setIsContactDetailsMenuOpen({
                      ...isContactDetailsMenuOpen,
                      [contact.contactId]: false
                    })
                    setSelectedContact(contact)
                    setIsRemoveContactModalVisible(true)
                  }
                }
              ]
            : [])
        ]
      }
    ] as EuiContextMenuPanelDescriptor[]
  }

  const makeContactPanel = (contact: CustomerContactContactFragment, roles: ContactCustomerRole[]) => (
    <EuiPanel data-test-id={`contact-container-${contact.contactId}`}>
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="s">
            {roles.length > 0 ? (
              roles.map((role) => (
                <EuiFlexItem key={role} grow={false}>
                  <ContactCustomerRoleBadge role={role} />
                </EuiFlexItem>
              ))
            ) : (
              <EuiFlexItem grow={false}>{/* <ContactCustomerRoleBadge /> */}</EuiFlexItem>
            )}
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false}>
              <EuiPopover
                isOpen={isContactDetailsMenuOpen[contact.contactId]}
                button={
                  <EuiButtonIcon
                    aria-label="show actions"
                    iconType="boxesHorizontal"
                    color="text"
                    onClick={() =>
                      setIsContactDetailsMenuOpen({
                        ...isContactDetailsMenuOpen,
                        [contact.contactId]: !isContactDetailsMenuOpen[contact.contactId]
                      })
                    }
                  />
                }
                closePopover={() =>
                  setIsContactDetailsMenuOpen({
                    ...isContactDetailsMenuOpen,
                    [contact.contactId]: false
                  })
                }
                ownFocus={true}
                panelPaddingSize="none"
              >
                <EuiContextMenu initialPanelId={0} panels={makeContactContextMenu(contact)} />
              </EuiPopover>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiHorizontalRule margin="s" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{contact.contact.detail && <ContactCard contact={contact.contact} />}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )

  // Pad array to ensure we have a multiple of 3
  const contactCellCount = Math.ceil(contacts.length / 3) * 3
  const contactCells = Array.from({ ...contacts, length: contactCellCount })

  return (
    <>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiTitle size="s">
            <h2>Contacts</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size="s" onClick={() => setIsCreateContactModalVisible(true)}>
            Add new contact
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size="s" onClick={() => setIsAddCustomerContactModalVisible(true)}>
            Add existing contact
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />

      <EuiFlexGroup wrap={true} gutterSize="l" justifyContent="flexStart">
        {contactCells.map((contact, contactIndex) => (
          <EuiFlexItem
            key={`contact-${contactIndex}`}
            grow={false}
            style={{ minWidth: '360px', flexBasis: 'calc((100% - 48px) / 3)' }}
          >
            {contact && makeContactPanel(contact, contact.roles)}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>

      {isContactDetailsModalVisible && selectedContact && (
        <EditContactForm contact={selectedContact.contact} onClose={() => setIsContactDetailsModalVisible(false)} />
      )}
      {isCreateContactModalVisible && (
        <CreateContactForm customer={customer} onClose={() => setIsCreateContactModalVisible(false)} />
      )}
      {isAddCustomerContactModalVisible && (
        <AddCustomerContactForm customerId={customer.id} onClose={() => setIsAddCustomerContactModalVisible(false)} />
      )}
      {isRemoveContactModalVisible && selectedContact && (
        <RemoveCustomerContactForm
          contact={selectedContact}
          customer={customer}
          onClose={() => setIsRemoveContactModalVisible(false)}
        />
      )}
      {isUpdateContactDataQualityModalVisible && selectedContact && (
        <UpdateContactDataQualityForm
          contactId={selectedContact.contactId}
          onClose={() => setIsUpdateContactDataQualityModalVisible(false)}
        />
      )}
    </>
  )
}

export const iconForContactCustomerRole = (role: ContactCustomerRole) => {
  switch (role) {
    case ContactCustomerRole.Primary:
      return 'starFilled'
    case ContactCustomerRole.Billing:
      return 'payment'
    default:
      return 'empty'
  }
}
