import { Route, Routes } from 'react-router'
import { InteractionMessageDetailContainer } from './interaction-message-detail/interaction-message-detail-container'
import { InteractionMessageListContainer } from './interaction-message-list/interaction-message-list-container'

const InteractionMessageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<InteractionMessageListContainer />} />
      <Route path=":id" element={<InteractionMessageDetailContainer />} />
      <Route path=":id/:tab" element={<InteractionMessageDetailContainer />} />
      <Route path="p/:index" element={<InteractionMessageListContainer />} />
    </Routes>
  )
}

export default InteractionMessageRoutes
