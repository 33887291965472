import {
  CustomerTypeSubmitWorkflowStepDetailInput,
  ServiceTypeSubmitWorkflowStepDetailInput,
  SingleChoiceSubmitWorkflowStepDetailInput,
  SubtopicSubmitWorkflowStepDetailInput,
  TopicSubmitWorkflowStepDetailInput,
  TradeSubmitWorkflowStepDetailInput,
  WorkflowCurrentStepFragment,
  WorkflowStepResultFragment,
  WorkflowStepType
} from '@fallonsolutions/types'
import { SubmitWorkflowStepDetailInput } from '../api/generated-types'

export const getInitialWorkflowResult = (
  currentStep: WorkflowCurrentStepFragment,
  stepResult?: WorkflowStepResultFragment
): SubmitWorkflowStepDetailInput => {
  switch (currentStep.step.type) {
    case WorkflowStepType.CallOut:
      return {
        callOut: {
          complete: true
        }
      }
    case WorkflowStepType.SingleChoice:
      return {
        singleChoice: {} as SingleChoiceSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.MultipleChoice:
      return {
        multipleChoice: {
          choiceIds: []
        }
      }
    case WorkflowStepType.Text:
      return {
        text: {
          value: stepResult?.value ?? ''
        }
      }
    case WorkflowStepType.CustomerType:
      return {
        customerType: {} as CustomerTypeSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.OutOfScope:
      return {
        outOfScope: {
          complete: true
        }
      }
    case WorkflowStepType.ServiceType:
      return {
        serviceType: {} as ServiceTypeSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Subtopic:
      return {
        subtopic: {} as SubtopicSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Topic:
      return {
        topic: {} as TopicSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Trade:
      return {
        trade: {} as TradeSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Success:
      return {
        success: {
          complete: true
        }
      }
    case WorkflowStepType.Failure:
      return {
        failure: {
          complete: true
        }
      }
    default:
      throw new Error(`Unsupported step type: ${currentStep.step.type}`)
  }
}
