import { ApolloClient } from '@apollo/client'
import {
  BookingWorkflowInput,
  CustomerType,
  ReverseWorkflowStepInput,
  SubmitWorkflowStepInput,
  WorkflowInput,
  WorkflowNextStepFragment,
  WorkflowStepChoiceFragmentDoc,
  WorkflowStepResultDetailFragment,
  WorkflowType
} from '@fallonsolutions/types'
import { WorkflowFragmentDoc } from '../api/generated-types'

export interface WorkflowOptimisticUpdateParams<I> {
  client: ApolloClient<any>
  input: I
  nextStep?: WorkflowNextStepFragment
  prevStep?: WorkflowStepResultDetailFragment
  IGNORE: any
}

export const submitWorkflowOptimisticUpdate = ({
  client,
  input,
  nextStep,
  IGNORE
}: WorkflowOptimisticUpdateParams<SubmitWorkflowStepInput>) => {
  const workflow = client.readFragment({
    id: `Workflow:${input.workflowId}`,
    fragment: WorkflowFragmentDoc,
    fragmentName: 'Workflow'
  })
  const choiceId =
    input.detail.singleChoice?.choiceId ??
    input.detail.trade?.choiceId ??
    input.detail.topic?.choiceId ??
    input.detail.subtopic?.choiceId ??
    input.detail.serviceType?.choiceId ??
    input.detail.customerType?.choiceId

  const choice = choiceId
    ? client.readFragment({
        id: `WorkflowStepChoice:${choiceId}`,
        fragment: WorkflowStepChoiceFragmentDoc,
        fragmentName: 'WorkflowStepChoice'
      })
    : undefined
  if (!workflow || !nextStep || !workflow.currentStep?.step) {
    console.debug('optimisticResponse: missing workflow or next step')
    if (!workflow) console.debug('optimisticResponse: no workflow')
    return IGNORE as any
  }

  const newStepResult = {
    __typename: 'WorkflowStepResult',
    stepId: input.stepId,
    step: workflow.currentStep?.step,
    choice: choice ?? null,
    value: null
  }

  const newCurrentStep = {
    ...workflow.currentStep,
    step: nextStep.step,
    nextSteps: []
  }

  const nextStepResult = {
    __typename: 'WorkflowStepResult',
    stepId: nextStep.step.id,
    step: nextStep.step,
    choice: null,
    value: null
  }

  const newInputValue = getNewInputValue(workflow.type, input)

  const updatedSteps = [
    ...workflow.steps.filter((stepResult) => stepResult.step.id !== newStepResult.step.id),
    newStepResult,
    nextStepResult
  ]

  const updatedWorkflow = {
    ...workflow,
    steps: updatedSteps,
    currentStep: newCurrentStep,
    state: {
      ...workflow.state,
      input: {
        ...workflow.state.input,
        ...newInputValue
      }
    }
  }
  return {
    submitWorkflowStep: {
      __typename: 'SubmitWorkflowStepPayload',
      workflow: updatedWorkflow
    }
  }
}

const getNewInputValue = (type: WorkflowType, input: SubmitWorkflowStepInput): Partial<WorkflowInput> => {
  switch (type) {
    case WorkflowType.Booking:
      return bookingGetNewInputValue(input)
    default:
      return {}
  }
}

const bookingGetNewInputValue = (input: SubmitWorkflowStepInput): Partial<BookingWorkflowInput> => {
  if (input.detail.topic) {
    return {
      topicId: input.detail.topic.choiceId
    }
  }
  if (input.detail.trade) {
    return {
      tradeId: input.detail.trade.choiceId
    }
  }
  if (input.detail.serviceType) {
    return {
      serviceTypeId: input.detail.serviceType.choiceId
    }
  }
  if (input.detail.subtopic) {
    return {
      subtopicId: input.detail.subtopic.choiceId
    }
  }
  if (input.detail.customerType) {
    return {
      customerType: input.detail.customerType.choiceId as CustomerType | undefined
    }
  }
  return {}
}

export const reverseWorkflowOptimisticUpdate = ({
  client,
  input,
  prevStep,
  IGNORE
}: WorkflowOptimisticUpdateParams<ReverseWorkflowStepInput>) => {
  const workflow = client.readFragment({
    id: `Workflow:${input.workflowId}`,
    fragment: WorkflowFragmentDoc,
    fragmentName: 'Workflow'
  })
  if (!workflow) {
    console.debug('optimisticResponse: no workflow')
    return IGNORE as any
  }
  if (!prevStep) {
    console.debug('optimisticResponse: no prevStep')
    return IGNORE as any
  }
  const newCurrentStep = {
    __typename: 'WorkflowCurrentStep',
    step: prevStep.step,
    prevStep: null,
    nextSteps: []
  }

  return {
    reverseWorkflowStep: {
      __typename: 'ReverseWorkflowStepPayload',
      workflow: {
        ...workflow,
        currentStep: newCurrentStep,
        steps: workflow.steps.slice(0, -1)
      }
    }
  }
}
