import { useSession } from '../auth/session-context'
import { LoadingSpinner } from '../loading/loading-spinner'
import UnauthenticatedRoutes from './routes-unauthenticated'

export interface UnauthenticatedRoutesWrapperProps {
  children: React.ReactNode
}

const UnauthenticatedRoutesWrapper = ({ children }: UnauthenticatedRoutesWrapperProps) => {
  const session = useSession()
  const isSignedIn = session.data?.user?.signedIn === true

  return (
    <>
      {session.loading ? (
        <LoadingSpinner message={'Authenticating'} style={{ marginTop: '200px' }} />
      ) : isSignedIn ? (
        children
      ) : (
        <UnauthenticatedRoutes />
      )}
    </>
  )
}

export default UnauthenticatedRoutesWrapper
