import { MoneyUtils } from '@fallonsolutions/money'
import Dinero from 'dinero.js'
import { isNil } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CreatePaymentApplicationInput,
  CreatePaymentForInvoiceDocument,
  CreatePaymentInput,
  Currency,
  PaymentApplicationType,
  PaymentMethod
} from '../api/generated-types'
import { CreatePaymentHeader } from './create-payment-header'

import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiForm,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'
import { InvoiceFragment, JobFragment } from '@fallonsolutions/types'
import { useAuthenticated } from '../auth/authenticated-context'
import { SelectedUser, getDefaultUserForJob } from '../common/get-default-user-for-job'
import { OperationSuccess } from '../common/operation-success'
import { CreateInvoicePaymentRow } from '../invoices/create-invoice-payment-row'
import { LocalPayment } from '../invoices/invoice-data/create-invoice-data'
import InvoiceSummary from '../invoices/invoice-summary'

export interface CreatePaymentProps {
  invoice: InvoiceFragment
  closeModal: () => void
  job: JobFragment | undefined
}

export const CreatePayment = (props: CreatePaymentProps) => {
  const { invoice, closeModal, job } = props
  const userFragment = useAuthenticated().userFragment
  const [errors, setErrors] = useState<string[]>([])
  const [showErrors, setShowErrors] = useState(false)
  const [createPayment, { data, loading, error }] = useMutation(CreatePaymentForInvoiceDocument, {
    refetchQueries: ['GetJob', 'GetInvoiceWithPayments', 'GetInvoice'],
    awaitRefetchQueries: true
  })

  const customerId = invoice.customer?.id ?? 'none'

  const [postDate, setPostDate] = useState(moment().tz('Australia/Brisbane'))
  const currentUser = getDefaultUserForJob({ job, user: userFragment, invoice, bias: 'Invoice' })
  const [user, setUser] = useState<SelectedUser | undefined>(currentUser)

  const [payment, setPayment] = useState<LocalPayment>({
    id: uuidv4(),
    amount: MoneyUtils.fromString(invoice.balance?.amount ?? '0.00'),
    method: PaymentMethod.DebitCard
  })

  const onChangePostDate = (postDate: Moment) => {
    console.log('change post date', postDate)
    setPostDate(postDate)
  }

  const onResetPostDate = () => setPostDate(moment().tz('Australia/Brisbane'))
  const onChangeTechnician = (technician?: any) => setUser(technician)
  const onResetTechnician = () => setUser(currentUser)
  const onChangePayment = (payment: LocalPayment) => {
    setErrors([])
    setPayment(payment)
  }

  const form = (
    <EuiForm isInvalid={showErrors && errors.length > 0} error={showErrors ? errors : []}>
      <CreatePaymentHeader
        invoice={invoice}
        editable={!loading && !data}
        postDate={postDate}
        user={user}
        onChangePostDate={onChangePostDate}
        onChangeTechnician={onChangeTechnician}
        onResetTechnician={onResetTechnician}
        onResetPostDate={onResetPostDate}
      />

      <EuiHorizontalRule />

      <div>
        <InvoiceSummary invoice={invoice} />
      </div>

      <CreateInvoicePaymentRow payment={payment} onChangePayment={onChangePayment} removable={false} />

      {error && (
        <div>
          <EuiCallOut size="s" title="Error creating payment, please try again" color="danger" iconType="alert" />
          <EuiSpacer />
        </div>
      )}
    </EuiForm>
  )

  const createApplications = (amount: Dinero.Dinero) => {
    const input: CreatePaymentApplicationInput = {
      amount: MoneyUtils.moneyToObject(amount),
      type: PaymentApplicationType.InvoicePaymentApplication,
      invoice: invoice.id
    }
    return [input]
  }

  const validateForm = (): string[] => {
    const balance = MoneyUtils.fromString(invoice.balance?.amount ?? '0.00')
    const errors: any[] = []
    if (isNil(invoice.balance?.amount)) {
      errors.push('Cannot create a payment against an invoice without outstanding balance')
      return errors
    }
    if (isNil(payment.method)) {
      errors.push('Payment method must be selected')
    }
    if (isNil(payment.amount)) {
      errors.push('Payment amount must be entered')
    }
    if (payment.amount?.isNegative()) {
      errors.push('Payment amount cannot be negative')
    }
    if (payment.amount?.greaterThan(balance)) {
      errors.push('Payment amount cannot exceed invoice balance')
    }
    if (payment.amount?.lessThan(Dinero({ amount: 1, currency: Currency.AUD }))) {
      errors.push('Payment amount cannot be zero')
    }
    return errors
  }

  const handleCreateInvoicePayment = () => {
    let input: CreatePaymentInput

    const amount = payment.amount ?? MoneyUtils.fromString('0.00')
    const applications = createApplications(amount)

    const errors = validateForm()
    setErrors(errors)
    if (errors.length > 0) {
      setShowErrors(true)
    } else {
      input = {
        id: payment.id,
        customer: customerId,
        invoice: invoice.id,
        amount: MoneyUtils.moneyToObject(amount),
        method: payment.method,
        applications,
        technician: user?.id,
        postDate: postDate.toISOString(true)
      }
      console.log('createPayment', input)
      return createPayment({ variables: { input } })
    }
  }

  const success = <OperationSuccess message={`Successfully created payment`} />

  return (
    <EuiOverlayMask>
      <EuiModal style={{ minWidth: '50vw', minHeight: '50vh' }} onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Create Payment</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <div>{data?.createPayment?.id ? success : form}</div>
        </EuiModalBody>
        {!data ? (
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={handleCreateInvoicePayment} fill isLoading={loading}>
              {loading ? 'Creating payment' : 'Create payment'}
            </EuiButton>
          </EuiModalFooter>
        ) : (
          <EuiButtonEmpty onClick={closeModal}>Done</EuiButtonEmpty>
        )}
      </EuiModal>
      )
    </EuiOverlayMask>
  )
}
