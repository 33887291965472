import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiPanel, EuiSpacer } from '@elastic/eui'
import { WorkflowNextStepFragment, WorkflowStepResultDetailFragment, WorkflowStepType } from '@fallonsolutions/types'
import { useEffect, useState } from 'react'
import { SubmitWorkflowStepDetailInput, SubmitWorkflowStepInput, WorkflowFragment } from '../api/generated-types'
import { BookingConfigFlowChartSidebar } from '../booking-config/explorer/booking-config-flow-chart-sidebar'
import { Callout } from '../common/callout'
import { WorkflowOutcomeView } from './workflow-outcome/workflow-outcome-view'
import { WorkflowV2StepView } from './workflow-step/workflow-v2-step-view'
import { WorkflowV2Header } from './workflow-v2-header'
import { getInitialWorkflowResult } from './workflow-v2-initial-result'
import { validateWorkflowResult } from './workflow-v2-validate-result'

export interface WorkflowV2ViewProps {
  workflow: WorkflowFragment
  loading?: boolean
  onNext: (input: SubmitWorkflowStepInput, nextStep?: WorkflowNextStepFragment) => void
  onBack: (prevStep?: WorkflowStepResultDetailFragment) => void
  onReset: () => void
  showSidebar?: boolean
}

export const WorkflowV2View = ({ loading, workflow, onNext, onBack, onReset, ...props }: WorkflowV2ViewProps) => {
  const currentStep = workflow.currentStep
  const step = currentStep?.step
  const showSidebar = props.showSidebar ?? false

  // const isFirstStep = !workflow.steps.length
  const input = workflow.state.input
  const autoSubmit =
    workflow.currentStep?.step.type &&
    [
      WorkflowStepType.CustomerType,
      WorkflowStepType.Trade,
      WorkflowStepType.Topic,
      WorkflowStepType.Subtopic,
      WorkflowStepType.ServiceType,
      WorkflowStepType.SingleChoice
      // WorkflowStepType.Success
    ].includes(workflow.currentStep.step.type)

  const stepResult = workflow.steps.find((s) => s.step.id === step?.id)

  const [result, setResult] = useState<SubmitWorkflowStepDetailInput | undefined>(
    step ? getInitialWorkflowResult(currentStep, stepResult) : undefined
  )

  useEffect(() => {
    setResult(step ? getInitialWorkflowResult(currentStep, stepResult) : undefined)
  }, [workflow.currentStep?.step])

  const onChangeResult = (result: SubmitWorkflowStepDetailInput) => {
    setResult(result)
    if (result && autoSubmit) {
      handleSubmit(result)
    }
  }

  const handleOnNext = () => {
    if (result) {
      handleSubmit(result)
    }
  }

  const handleBack = (prevStep?: WorkflowStepResultDetailFragment) => {
    onBack(prevStep ?? currentStep?.prevStep ?? undefined)
  }

  const handleSubmit = (result: SubmitWorkflowStepDetailInput) => {
    if (step) {
      const nextStep = workflow.currentStep?.nextSteps.find(
        (s) =>
          s.choiceId === result.singleChoice?.choiceId ||
          s.choiceId === result.customerType?.choiceId ||
          s.choiceId === result.trade?.choiceId ||
          s.choiceId === result.topic?.choiceId ||
          s.choiceId === result.subtopic?.choiceId ||
          s.choiceId === result.serviceType?.choiceId ||
          !s.choiceId
      )
      onNext(
        {
          workflowId: workflow.id,
          stepId: step.id,
          detail: result
        },
        nextStep
      )
    }
  }

  const inputFields = [
    {
      name: 'Customer type',
      value: input.customerType ?? ''
    },
    {
      name: 'Trade',
      value: input.trade?.name ?? ''
    },
    {
      name: 'Topic',
      value: input.topic?.name ?? ''
    },
    {
      name: 'Service type',
      value: input.serviceType?.name ?? ''
    },
    {
      name: 'Subtopic',
      value: input.subtopic?.name ?? ''
    },
    ...(input.steps
      ?.filter((step) =>
        [WorkflowStepType.SingleChoice, WorkflowStepType.MultipleChoice, WorkflowStepType.Text].includes(step.step.type)
      )
      .map((step) => ({
        name: step.step.name,
        value: step.choice?.label ?? step.value ?? ''
      })) ?? [])
  ]

  const isValid =
    step && result
      ? validateWorkflowResult(step.type, result)
      : step
        ? [WorkflowStepType.Success, WorkflowStepType.Failure].includes(step.type)
        : false

  const isSuccess = step?.type === WorkflowStepType.Success
  const isFailure = step?.type === WorkflowStepType.OutOfScope || step?.type === WorkflowStepType.Failure

  return (
    <>
      <div style={showSidebar ? { width: '100%', minHeight: '720px', position: 'relative' } : {}}>
        {showSidebar && <BookingConfigFlowChartSidebar inputFields={inputFields} outcome={workflow.state.outcome} />}

        <div style={{ maxWidth: '900px' }}>
          <div>
            <EuiPanel hasShadow={false} hasBorder={true}>
              <WorkflowV2Header
                loading={loading}
                workflow={workflow}
                onBack={(prevStep) => handleBack(prevStep)}
                onReset={onReset}
              />
              <EuiSpacer size="m" />
              {step && result ? (
                <>
                  <WorkflowV2StepView
                    input={input}
                    result={result}
                    onChange={onChangeResult}
                    step={step}
                    loading={loading}
                  />
                  {isSuccess && (
                    <>
                      <EuiSpacer />
                      <div style={{ maxWidth: '350px' }}>
                        <WorkflowOutcomeView outcome={workflow.state.outcome} showPanel={true} />
                      </div>
                    </>
                  )}
                </>
              ) : loading ? (
                <EuiLoadingSpinner />
              ) : (
                <Callout title="Workflow complete" />
              )}

              {step && !autoSubmit && (
                <>
                  <EuiSpacer size="l" />
                  <EuiFlexGroup>
                    <EuiFlexItem grow={true} />
                    <EuiFlexItem grow={false}>
                      <EuiButton onClick={handleOnNext} color="primary" fill isDisabled={loading || !isValid}>
                        {isSuccess || isFailure ? 'Done' : 'Next'}
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              )}
            </EuiPanel>
          </div>
        </div>
      </div>
    </>
  )
}
