import { MaybeMasked, OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client'
import { EuiBreadcrumb, EuiBreadcrumbs, EuiCallOut, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui'
import { capitalize } from 'lodash-es'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export interface DetailContainerRouteParams extends Record<string, string | undefined> {
  id: string
  tab?: string
}

export interface DetailContainerProps<TData = any, TVariables extends OperationVariables = OperationVariables> {
  documentNode: TypedDocumentNode<TData, TVariables>
  variables?: TVariables
  parentPath: string
  modelName: string
  modelNamePlural?: string
  showBreadcrumbs?: boolean
  pollInterval?: number
  view: (data: MaybeMasked<TData>) => any
  getTitle: (data: MaybeMasked<TData>) => string
}

export function DetailContainer<TData = any, TVariables extends OperationVariables = OperationVariables>(
  props: DetailContainerProps<TData, TVariables>
) {
  const { parentPath, modelName, modelNamePlural, documentNode, variables, view, getTitle } = props

  const showBreadcrumbs = props.showBreadcrumbs ?? true
  const navigate = useNavigate()

  const { loading, error, data, previousData } = useQuery(documentNode, {
    variables,
    ...(props.pollInterval && { pollInterval: props.pollInterval })
  })

  const result = data ?? previousData

  const title = loading ? `Loading ${modelName}...` : result ? getTitle(result) : 'No data'

  useEffect(() => {
    document.title = title
  }, [title])

  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: capitalize(modelNamePlural ?? `${modelName}s`),
      href: '#',
      onClick: (e) => {
        e.preventDefault()
        navigate(parentPath)
      }
    },
    {
      text: title
    }
  ]

  return (
    <>
      {showBreadcrumbs && (
        <>
          <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
          <EuiSpacer />
        </>
      )}
      {error ? (
        <EuiCallOut color="danger" title="Error encountered">
          {error.message}
        </EuiCallOut>
      ) : loading && !result ? (
        <EuiLoadingSpinner size="l" />
      ) : result ? (
        view(result)
      ) : (
        <EuiCallOut color="danger" title="Error loading data" />
      )}
    </>
  )
}
