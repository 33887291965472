import { SuccessSubmitWorkflowStepDetailInput, SuccessWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type SuccessWorkflowV2StepProps = WorkflowV2StepProps<
  SuccessWorkflowStepDetailFragment,
  SuccessSubmitWorkflowStepDetailInput
>

export const SuccessWorkflowV2Step = ({ detail }: SuccessWorkflowV2StepProps) => {
  return (
    <>
      <Callout type="success" title="Success">
        {detail.message}
      </Callout>
    </>
  )
}
