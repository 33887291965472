import { FailureSubmitWorkflowStepDetailInput, FailureWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type FailureWorkflowV2StepProps = WorkflowV2StepProps<
  FailureWorkflowStepDetailFragment,
  FailureSubmitWorkflowStepDetailInput
>

export const FailureWorkflowV2Step = ({ detail }: FailureWorkflowV2StepProps) => {
  return (
    <>
      <Callout type="failure" title="Failure">
        {detail.message}
      </Callout>
    </>
  )
}
