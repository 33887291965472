import React, { useContext } from 'react'
import { LoaderContext, initialLoaderContext } from '../app/loader-context'
import { SessionState } from './session'

export interface NewSessionState {
  expiry?: string
  user?: SessionState
}

export interface ISessionContext extends NewSessionState {
  signIn: () => void
  signOut: () => void
  handleCallback: (url: string, redirectUrl?: string) => void
  refresh: () => Promise<SessionState | undefined>
}

export type SessionLoaderContext = LoaderContext<ISessionContext>

export const SessionContext = React.createContext<SessionLoaderContext>(initialLoaderContext)

export const useSession = () => {
  const context: SessionLoaderContext = useContext(SessionContext)
  if (!context) {
    throw new Error('needs SessionContext as provider')
  }
  return context
}
