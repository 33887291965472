import { useApolloClient, useMutation } from '@apollo/client'
import { WorkflowFragment, WorkflowNextStepFragment, WorkflowStepResultDetailFragment } from '@fallonsolutions/types'
import {
  GetWorkflowDocument,
  GetWorkflowQuery,
  ResetWorkflowDocument,
  ReverseWorkflowStepDocument,
  SubmitWorkflowStepDocument,
  SubmitWorkflowStepInput
} from '../api/generated-types'
import { DetailContainer } from '../common/detail-container'
import {
  reverseWorkflowOptimisticUpdate,
  submitWorkflowOptimisticUpdate
} from './workflow-v2-mutation-optimistic-update'
import { WorkflowV2View } from './workflow-v2-view'

export interface WorkflowV2ContainerProps {
  workflowId: string
  showSidebar?: boolean
  showBreadcrumbs?: boolean
  onSubmit?: (response?: WorkflowFragment) => void
}

const optimisticUpdateEnabled = true

export const WorkflowV2Container = ({ workflowId, onSubmit, showSidebar, ...props }: WorkflowV2ContainerProps) => {
  const client = useApolloClient()

  const showBreadcrumbs = props?.showBreadcrumbs ?? true

  const [submitWorkflowStep, { loading: submitLoading }] = useMutation(SubmitWorkflowStepDocument)
  const [reverseWorkflowStep, { loading: reverseLoading }] = useMutation(ReverseWorkflowStepDocument)
  const [resetWorkflowStep, { loading: resetLoading }] = useMutation(ResetWorkflowDocument)

  const loading = submitLoading || reverseLoading || resetLoading || false

  const onNext = async (input: SubmitWorkflowStepInput, nextStep?: WorkflowNextStepFragment) => {
    console.debug('workflow: nextStep')
    console.time('workflow: nextStep')
    const results = await submitWorkflowStep({
      variables: { input },
      ...(optimisticUpdateEnabled
        ? {
            optimisticResponse: (vars, { IGNORE }) =>
              submitWorkflowOptimisticUpdate({ client, input: vars.input, nextStep, IGNORE })
          }
        : {})
    })
    console.timeEnd('workflow: nextStep')
    onSubmit && results && onSubmit(results?.data?.submitWorkflowStep.workflow ?? undefined)
  }

  const onBack = async (prevStep?: WorkflowStepResultDetailFragment) => {
    console.log('workflow: prevStep')
    console.time('workflow: prevStep')
    const results = await reverseWorkflowStep({
      variables: { input: { workflowId } },
      ...(optimisticUpdateEnabled
        ? {
            optimisticResponse: (vars, { IGNORE }) =>
              reverseWorkflowOptimisticUpdate({ client, input: vars.input, prevStep, IGNORE })
          }
        : {})
    })
    console.timeEnd('workflow: prevStep')
    onSubmit && results && onSubmit(results.data?.reverseWorkflowStep.workflow ?? undefined)
  }

  const onReset = async () => {
    console.log('workflow: reset')
    console.time('workflow: reset')
    const results = await resetWorkflowStep({
      variables: { input: { workflowId } }
    })
    console.timeEnd('workflow: reset')
    onSubmit && results && onSubmit(results.data?.resetWorkflow.workflow ?? undefined)
  }

  const view = (data: GetWorkflowQuery) => {
    const workflow = data.getWorkflow?.workflow
    return workflow ? (
      <WorkflowV2View
        loading={loading}
        workflow={workflow}
        onNext={onNext}
        onBack={onBack}
        onReset={onReset}
        showSidebar={showSidebar}
      />
    ) : (
      <></>
    )
  }

  return (
    <DetailContainer
      variables={{ input: { workflowId } }}
      view={view}
      parentPath={'/workflow-v2'}
      documentNode={GetWorkflowDocument}
      modelName="workflow"
      modelNamePlural="workflows"
      getTitle={(data) => data?.getWorkflow?.workflow?.reference ?? 'loading'}
      showBreadcrumbs={showBreadcrumbs}
    />
  )
}

export default WorkflowV2Container
