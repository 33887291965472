import { WorkflowStepType } from '@fallonsolutions/types'
import { SubmitWorkflowStepDetailInput } from '../api/generated-types'

export const validateWorkflowResult = (type: WorkflowStepType, result: SubmitWorkflowStepDetailInput): boolean => {
  switch (type) {
    case WorkflowStepType.CallOut:
      return true
    case WorkflowStepType.SingleChoice:
      return !!result.singleChoice?.choiceId
    case WorkflowStepType.MultipleChoice:
      return !!result.multipleChoice?.choiceIds?.length
    case WorkflowStepType.Text:
      return !!result.text?.value
    case WorkflowStepType.CustomerType:
      return !!result.customerType?.choiceId
    case WorkflowStepType.OutOfScope:
      return true
    case WorkflowStepType.ServiceType:
      return !!result.serviceType?.choiceId
    case WorkflowStepType.Subtopic:
      return !!result.subtopic?.choiceId
    case WorkflowStepType.Topic:
      return !!result.topic?.choiceId
    case WorkflowStepType.Trade:
      return !!result.trade?.choiceId
    case WorkflowStepType.Success:
      return true
    case WorkflowStepType.Failure:
      return true
    default:
      throw new Error(`Unsupported step type: ${type}`)
  }
}
