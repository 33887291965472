import { dateConfig } from '@fallonsolutions/date'
import { captureMessage } from '@sentry/react'
import { includes } from 'lodash-es'
import { DateTime, DurationLike } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { LoadingError } from '../loading/loading-error'
import { LoadingSpinner } from '../loading/loading-spinner'
import createPersistedState from '../use-persisted-state'

const useAppConfigCacheExpiry = createPersistedState<string>('appConfigExpiry')
const useAppConfigCache = createPersistedState<any>('appConfig')
const useConfigUrl = createPersistedState<string | undefined>('appConfigUrl')

const devDomain = 'app.fsplatformdev.net'
const localhost = 'localhost'

const cacheExpiryDuration: DurationLike = { minutes: 10 }

interface AppContextProviderProps {
  children: React.ReactNode
}

export interface IAppContext {
  configUrl: string
  region: string
  stage: string
  loginKeyPrefix: string
  buildVersion: string
  appDomain: string
  mediaDomain: string
  authDomain: string
  graphqlUrl: string
  identityPoolId: string
  userPoolId: string
  cognitoClientId: string
  googleApiKey: string
  mixpanelToken: string
  myobCompany: string
  photoBucketName: string
  documentBucketName: string
  jobsMediaUploadBucket: string
  jobsMediaUploadDirectory: string
  staticBucketName: string
  uploadBucketName: string
  fiservTransactionCsvUploadPath: string
  sentryDsn?: string
  sentryTraceSampleRate?: number
  authCallbackUri: string
  authSignoutUri: string
  tokenScopes: string[]
  isDev: boolean
  isTest: boolean
  isProd: boolean
  crispWebsiteId: string
  hubspotAccountId: string
}

export const AppContext = React.createContext<IAppContext>({
  configUrl: '',
  region: '',
  stage: '',
  loginKeyPrefix: '',
  buildVersion: '',
  appDomain: '',
  mediaDomain: '',
  authDomain: '',
  graphqlUrl: '',
  identityPoolId: '',
  userPoolId: '',
  cognitoClientId: '',
  googleApiKey: '',
  mixpanelToken: '',
  myobCompany: '',
  photoBucketName: '',
  documentBucketName: '',
  jobsMediaUploadBucket: '',
  jobsMediaUploadDirectory: '',
  staticBucketName: '',
  uploadBucketName: '',
  fiservTransactionCsvUploadPath: '',
  authCallbackUri: '',
  authSignoutUri: '',
  tokenScopes: [],
  isDev: false,
  isTest: false,
  isProd: false,
  crispWebsiteId: '',
  hubspotAccountId: ''
})

export const AppContextProvider = (props: AppContextProviderProps) => {
  const [appContext, setAppContext] = useState<IAppContext | undefined>(undefined)
  const [cacheExpiry, setCacheExpiry] = useAppConfigCacheExpiry('')
  const [cache, setCache] = useAppConfigCache({})
  const [error, setError] = useState<Error | unknown | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [configUrlOverride] = useConfigUrl(undefined)

  const clearCache = () => {
    setCache({})
    setCacheExpiry('')
  }

  const setAppContextFromCache = () => {
    setAppContext(cache)
    setLoading(false)
  }

  const fetchAppContext = async () => {
    try {
      setLoading(true)
      const locationConfigUrl = getConfigUrlFromLocation(window.location)
      const configUrl = configUrlOverride ?? locationConfigUrl
      console.log('fetching', configUrl)
      const response = await fetch(configUrl, {
        headers: {
          'Content-Type': 'application/json',
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      })
      const appConfig = await response.json()
      setLoading(false)
      if (appConfig.appDomain) {
        const appContext = createAppContext(appConfig)
        const now = DateTime.now().setZone(dateConfig.defaultTimezone).plus(cacheExpiryDuration).toISO()
        setCacheExpiry(now)
        setCache(appContext)
        setAppContext(appContext)
      } else {
        clearCache()
        setError(new Error('error loading app context from response'))
      }
    } catch (err: unknown) {
      console.error('error fetching app config')
      captureMessage(`error fetching app config`)
      setLoading(false)
      setError(err)
      clearCache()
    }
  }

  useEffect(() => {
    if (cache && !isCacheExpired(cacheExpiry)) {
      console.log('getting config from cache')
      setAppContextFromCache()
    } else {
      console.log('getting config from server')
      fetchAppContext()
    }
  }, [])

  const validAppContext = !error && !loading && appContext

  return (
    <>
      {validAppContext ? (
        <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>
      ) : loading ? (
        <LoadingSpinner message="Bootstrapping" />
      ) : (
        <LoadingError
          message="There was a problem retrieving app configuration from the server"
          retryClick={() => fetchAppContext()}
        />
      )}
    </>
  )
}

const isCacheExpired = (cacheExpiry: string | undefined) => {
  if (!cacheExpiry) return true
  const cacheExpiryDate = DateTime.fromISO(cacheExpiry)
  return !cacheExpiryDate || cacheExpiryDate < DateTime.now()
}

const createAppContext = (appConfig: any): IAppContext => {
  const { stage } = appConfig
  const isDev = includes(['LOCAL', 'DEV'], stage)
  const isTest = includes(['TEST'], stage)
  const isProd = includes(['PROD'], stage)

  const appDomain = window.location.hostname === localhost ? window.location.host : appConfig.appDomain
  console.log('appConfig.appDomain', appConfig.appDomain)
  console.log('appDomain', appDomain)

  const authCallbackUri = `${appDomain}/auth/callback`
  const authSignoutUri = `${appDomain}/sso/signout`
  const tokenScopes = ['openid', 'email', 'profile']

  const appContext: IAppContext = {
    ...appConfig,
    appDomain,
    authCallbackUri,
    authSignoutUri,
    tokenScopes,
    isDev,
    isTest,
    isProd
  }
  return appContext
}

const getConfigUrlFromLocation = (location: Location) => {
  const host = location.hostname === 'localhost' ? devDomain : location.host
  return `https://${host}/config`
}

export const useApp = () => useContext(AppContext)
