import {
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiText,
  EuiTextColor
} from '@elastic/eui'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'
import {
  ContactDetailFragment,
  JobLinkFragment,
  ReviewFlag,
  ReviewFragment,
  ReviewReplyFragment,
  ReviewStatusType,
  TradeType,
  UserLinkFragment
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { dateConfig } from '../common/date-config-luxon'
import EuiCustomLink from '../common/eui-custom-link'
import TradeTag from '../common/trade-tag'
import { decamelise, makeHumanReadable } from '../common/utils'
import UserLink from '../users/user-link'
import { ReviewStatusBadge } from './review-status-badge'

export interface ReviewListColumnsProps {
  onClickReply: (review: ReviewFragment) => void
  onClickEdit: (review: ReviewFragment) => void
}

export const ReviewListColumns = ({
  onClickReply,
  onClickEdit
}: ReviewListColumnsProps): Array<EuiBasicTableColumn<ReviewFragment>> => {
  const userFragment = useAuthenticated().userFragment
  const canAddReply = userFragment.permissions?.manageReviews === true
  const canEditReview = userFragment.permissions?.manageReviews === true
  const navigate = useNavigate()

  return [
    {
      field: 'reference',
      name: 'Reference',
      width: '100px',
      render: (reference, review) =>
        review.score < 2 ? <EuiTextColor color="danger">{reference}</EuiTextColor> : reference
    },
    {
      field: 'flag',
      name: 'Flag',
      width: '80px',
      render: (flag: ReviewFlag | undefined) => flag !== ReviewFlag.None && makeHumanReadable(flag)
    },
    {
      field: 'trade',
      name: 'Trade',
      width: '120px',
      render: (trade: TradeType | undefined) => (
        <EuiFlexGroup gutterSize="none" alignItems="center">
          <EuiFlexItem grow={false}>
            <TradeTag trade={trade ?? TradeType.None} style={{ marginRight: '9px' }} />
          </EuiFlexItem>
          <EuiFlexItem grow={true}>
            <span>{decamelise(trade)}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'reviewer',
      name: 'Reviewer',
      width: '120px',
      render: (contact: ContactDetailFragment, review) => {
        if (review.customerId) {
          return (
            <EuiLink onClick={() => navigate(`/customers/${review.customerId}`)}>
              {contact.fullName ?? 'No name'}
            </EuiLink>
          )
        } else {
          return contact.fullName ?? 'No name'
        }
      }
    },
    {
      field: 'score',
      name: 'Score',
      width: '100px',
      render: (score: number) => {
        const fullStars = Math.floor(score)
        const isHalfStar = score % 1 > 0
        const starCharacters = '★'.repeat(fullStars)
        const output = starCharacters + (isHalfStar ? '☆' : '')
        return score < 2 ? <EuiTextColor color="danger">{output}</EuiTextColor> : output
      }
    },
    {
      field: 'body',
      name: 'Review',
      render: (body: string, review: ReviewFragment) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <EuiMarkdownFormat textSize="xs">{body}</EuiMarkdownFormat>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiSpacer size="xs" />
              <EuiText size="xs">
                {review.link ? (
                  <EuiLink href={review.link} target="_blank">
                    {makeHumanReadable(review.source)}
                  </EuiLink>
                ) : (
                  makeHumanReadable(review.source)
                )}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'replies',
      name: 'Reply',
      render: (replies: ReviewReplyFragment[] | undefined) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="s">
            {replies?.map((reply, index) => (
              <>
                {index > 0 && <EuiSpacer size="s" />}
                <EuiFlexItem>
                  <EuiMarkdownFormat textSize="xs">{reply.message}</EuiMarkdownFormat>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSpacer size="xs" />
                  <EuiText size="xs">
                    {reply.user?.contactDetail?.fullName ?? 'No name'} on{' '}
                    {DateTime.fromISO(reply.date).toFormat(dateConfig.fullDate)}
                  </EuiText>
                </EuiFlexItem>
              </>
            ))}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'users',
      name: 'User(s) mentioned',
      width: '200px',
      render: (users: UserLinkFragment[]) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="xs">
            {users.map((user, index) => (
              <EuiFlexItem key={user.id}>
                {index > 0 && <EuiSpacer size="xs" />}
                <UserLink user={user} />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'jobs',
      name: 'Job(s)',
      width: '100px',
      render: (jobs: JobLinkFragment[] | undefined) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="xs">
            {jobs ? (
              jobs.map((job) => (
                <EuiFlexItem key={job.id}>
                  <EuiCustomLink to={`/jobs/${job.id}`}>{job.number}</EuiCustomLink>
                </EuiFlexItem>
              ))
            ) : (
              <EuiFlexItem />
            )}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'date',
      name: 'Date',
      width: '140px',
      align: 'right',
      render: (value: any) => {
        const eventDate = DateTime.fromISO(value)
        return eventDate.isValid ? eventDate.toFormat(dateConfig.fullDate) : ''
      }
    },
    {
      field: 'status.status',
      name: 'Status',
      width: '100px',
      render: (status: ReviewStatusType) => <ReviewStatusBadge status={status} />
    },
    {
      name: '',
      width: '60px',
      actions: [
        {
          available: () => canAddReply,
          name: 'Add reply',
          description: 'Add a reply for this review',
          icon: 'discuss',
          type: 'icon',
          onClick: (review: ReviewFragment) => onClickReply(review)
        },
        {
          available: () => canEditReview,
          name: 'Edit review',
          description: 'Edit this review',
          icon: 'pencil',
          type: 'icon',
          onClick: (review: ReviewFragment) => onClickEdit(review)
        }
      ]
    }
  ]
}
