/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
  EuiTitle
} from '@elastic/eui'
import { contactFragmentToContactInput } from '@fallonsolutions/contact'
import {
  ContactFragment,
  CustomerLocationFragment,
  CustomerType,
  JobTopic,
  JobTopicType,
  JobTopics,
  WebFormDetailFragment
} from '@fallonsolutions/types'
import { cloneDeep } from 'lodash-es'
import { DateTime } from 'luxon'
import { Dict } from 'mixpanel-browser'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'
import { GetCustomerLinkDocument } from '../api/generated-types'
import { useApp } from '../app/app-context'
import { useMixpanel } from '../app/mixpanel-provider'
import { useAuthenticated } from '../auth/authenticated-context'
import { createInitialContactDetails } from '../common/create-intitial-contact-details'
import { isCustomerExistingMember } from '../common/existing-customer'
import { formatPhone } from '../common/phone'
import RawJSON from '../common/raw-json'
import createPersistedState from '../use-persisted-state'
import { AbortEnquiryAction } from './actions/action-abort-enquiry'
import { ContactAction, ContactActionResult, ContactDetails, CustomerContactDetails } from './actions/action-contact'
import { CustomerActionResult } from './actions/action-customer'
import { ContactRole } from './actions/action-customer-qualification'
import { EnquiryTypeAction, EnquiryTypeActionResult } from './actions/action-enquiry-type'
import { EnquiryPersistenceEnum, useCreateEnquiryService } from './create-enquiry-service'
import { useEnquiryService } from './enquiry-service'
import { WorkflowProvider } from './helpers/workflow-provider'
import { createNewBookingState } from './new-enquiry-create-initial-new-booking-state'
import { EnquiryWebFormView } from './new-enquiry-webform'
import { WorkflowComplete } from './workflow-complete'
import { OnChangeContactProps } from './workflow-interaction-message-model'
import { BaseActionResult, EnquiryAbortDetails, EnquiryTypeReference } from './workflow-model'
import { ActionForEnquiryType, NewEnquiryResult, getWorkflowFields } from './workflow-new-enquiry-model'

const useEnquiryNotes = createPersistedState<string>('new-enquiry-notes')

export const NEW_CUSTOMER_KEY = 'NEW_CUSTOMER'

interface NewEnquiryWorkflowProps {
  contact?: ContactFragment | undefined
  customerLocation: CustomerLocationFragment | undefined
  fastTrackBookingEnabled?: boolean
  editFastTrackBookingState?: boolean
  setFastBookingScript?: (value: boolean) => void
  existingJob?: string | undefined
  customerId?: string | undefined
  enquiryId: string
  webForm: WebFormDetailFragment | undefined | null
}

export const NewEnquiryWorkflow = ({
  contact: initialContact,
  fastTrackBookingEnabled,
  editFastTrackBookingState,
  existingJob,
  customerLocation,
  customerId,
  enquiryId,
  webForm,
  setFastBookingScript
}: NewEnquiryWorkflowProps) => {
  const mixpanel = useMixpanel()

  // TODO: refactor to merge newCustomerId and customerIdentifier
  const [newCustomerId] = useQueryParam('customerId', StringParam, { updateType: 'replaceIn' })
  const [customerIdentifier, setCustomerIdentifier] = useState<string | undefined>(newCustomerId ?? customerId)
  const isDev = useAuthenticated().developerFeatures

  const [firstName] = useQueryParam('firstName', StringParam, { updateType: 'replaceIn' })
  const [lastName] = useQueryParam('lastName', StringParam, { updateType: 'replaceIn' })
  const [phone] = useQueryParam('phone', StringParam, { updateType: 'replaceIn' })
  const [suburb] = useQueryParam('suburb', StringParam, { updateType: 'replaceIn' })
  const [suburbId] = useQueryParam('suburbId', StringParam, { updateType: 'replaceIn' })
  const [enquiryType] = useQueryParam('enquiryType', StringParam, { updateType: 'replaceIn' })
  const initialEnquiryType = enquiryType as EnquiryTypeReference | undefined
  const initialContactActionCompleted = !!firstName && !!lastName && !!phone && !!suburbId && !!suburb
  const appContext = useApp()
  const navigate = useNavigate()

  const [enquiryNotes, setEnquiryNotes] = useEnquiryNotes('')

  const [_, setWorkflowTopicV2] = useState(false)

  //  const canViewActiveInteractions = userFragment.callCenter?.participation === UserCallCenterParticipationType.Active

  //use isDev switch to get basic persistence
  //E2E tests depends on old flow with enquiry w/o new flow used when persisting
  const persistence = !appContext.isProd
    ? isDev
      ? EnquiryPersistenceEnum.Basic
      : EnquiryPersistenceEnum.None
    : appContext.isProd
      ? EnquiryPersistenceEnum.Basic
      : EnquiryPersistenceEnum.None

  const contactInput: ContactActionResult =
    initialContact && fastTrackBookingEnabled
      ? {
          firstName: initialContact?.detail?.firstName ?? undefined,
          lastName: initialContact?.detail?.lastName ?? undefined,
          phone: initialContact?.detail?.phone ? formatPhone(initialContact?.detail.phone) : undefined,
          ...(suburbId &&
            suburb && {
              suburb: {
                id: suburbId,
                label: suburb
              }
            }),
          actionCompleted: true
        }
      : {
          firstName: firstName ?? undefined,
          lastName: lastName ?? undefined,
          phone: phone ? formatPhone(phone) : undefined,
          ...(suburbId &&
            suburb && {
              suburb: {
                id: suburbId,
                label: suburb
              }
            }),
          actionCompleted: initialContactActionCompleted
        }

  const [
    workflowState,
    { loading, updateResult, addResponse, goBack, abortEnquiry, setContact, completeEnquiry, clearResponses }
  ] = useEnquiryService<NewEnquiryResult>({
    enquiryId,
    initialResult: {
      contact: contactInput,
      enquiryType: {
        enquiryType: enquiryType && fastTrackBookingEnabled ? (enquiryType as EnquiryTypeReference) : undefined
      },
      ...(fastTrackBookingEnabled
        ? // currently fast booking will only work for a new booking, not the other categories like cancellation, complaint, etc

          {
            newBooking: createNewBookingState(customerId, existingJob, customerLocation)
          }
        : {})
    },
    persistence
  })

  // once a certain milestone in the booking process has been reached prevent changing the fast track switch -> this is to prevent weird state changes from occuring
  const disableFastTrackBookingSwitch = !!workflowState.result?.newBooking?.jobTopic

  // react to changes in fastTrackBookingEnabled
  useEffect(() => {
    updateResult((current) => ({
      ...current,
      newBooking: {
        ...current.newBooking,
        fastTrack: {
          fastBooking: !!fastTrackBookingEnabled,
          newCustomer: !!customerId
        }
      }
    }))
  }, [fastTrackBookingEnabled])

  const { data } = useQuery(GetCustomerLinkDocument, {
    skip: !customerIdentifier || customerIdentifier === NEW_CUSTOMER_KEY,
    ...(customerIdentifier && { variables: { id: customerIdentifier } })
  })

  useEffect(() => {
    const customer = data?.getCustomer
    if (customerIdentifier === NEW_CUSTOMER_KEY) {
      updateResult((current) => ({
        ...current,
        customer: {
          actionCompleted: true
        }
      }))
    } else if (customer) {
      const customerSuburb =
        customer.mainContact?.detail?.address?.suburbId && customer.mainContact?.detail?.address?.suburb
          ? {
              id: customer.mainContact.detail.address.suburbId,
              label: customer.mainContact.detail.address.suburb
            }
          : undefined
      const contact: ContactActionResult = {
        id: customer.mainContact?.id ?? undefined,
        customerId: customer.id,
        firstName: customer.mainContact?.detail?.firstName ?? '',
        lastName: customer.mainContact?.detail?.lastName ?? '',
        phone: customer.mainContact?.detail?.phone ? formatPhone(customer.mainContact.detail.phone) : '',
        ...(customerSuburb && { suburb: customerSuburb })
      }
      setContactEntryCompleted(true)
      const customerResult: CustomerActionResult = {
        customer: {
          id: customer.id,
          reference: customer.number ?? 'Unknown',
          label: customer.mainContact?.detail?.fullName ?? 'Unknown',
          firstName: customer.mainContact?.detail?.firstName ?? 'Unknown',
          fullName: customer.mainContact?.detail?.fullName ?? 'Unknown',
          email: customer.mainContact?.detail?.email ?? '',
          phone: customer.mainContact?.detail?.phone ? formatPhone(customer.mainContact.detail.phone) : '',
          contacts: customer.contacts ?? []
        },
        ...(!!initialContact &&
          fastTrackBookingEnabled && {
            contact: initialContact,
            actionCompleted: true
          })
      }
      updateResult((current) => ({
        ...current,
        contact,
        customer: customerResult,
        ...(initialEnquiryType === EnquiryTypeReference.NewBooking &&
          fastTrackBookingEnabled && {
            newBooking: {
              ...current.newBooking,
              fastTrack: {
                fastBooking: true,
                newCustomer: false
              },
              customer: customerResult,
              customerQualification: {
                existingCustomer: true,
                existingMember: isCustomerExistingMember(customerLocation, customer.type ?? CustomerType.None),
                contactRole:
                  (customer.type ?? CustomerType.None) === CustomerType.Domestic
                    ? ContactRole.HomeOwner
                    : ContactRole.Other,
                actionCompleted: true
              }
            }
          })
      }))

      setContactDetails({
        id: customer.mainContact?.id ?? undefined,
        customerId: customerIdentifier,
        firstName: contact.firstName ?? '',
        lastName: contact.lastName ?? '',
        phone: contact.phone ? formatPhone(contact.phone) : '',
        ...(customerSuburb && { suburb: customerSuburb })
      })
      // Call SetContact in EnquiryService to call mutation
      setContact({
        contactId: contact.id ?? undefined,
        customerId,
        contactInput: customerResult.contact ? contactFragmentToContactInput(customerResult.contact) : undefined
      })
    }
  }, [data, customerIdentifier])

  const [contactEntryCompleted, setContactEntryCompleted] = useState(initialContactActionCompleted)
  const [contactDetails, setContactDetails] = useState<ContactDetails | CustomerContactDetails>(
    createInitialContactDetails({ suburb, suburbId, firstName, lastName, phone, contact: initialContact, customerId })
  )

  const [abortRequested, setAbortRequested] = useState(false)
  const [abortDetails, setAbortDetails] = useState<EnquiryAbortDetails | undefined>(undefined)

  const onAbortRequested = (details: EnquiryAbortDetails) => {
    setAbortDetails(details)
    setAbortRequested(true)
  }

  const onCancelAbort = () => setAbortRequested(false)

  const [topicChangeRequested, setTopicChangeRequested] = useState(false)
  const [topicChangeDetails, setTopicChangeDetails] = useState<JobTopic | undefined>(undefined)

  const onChangeTopicRequested = (jobTopic: JobTopicType) => {
    const topic = JobTopics.find((t) => t.reference === jobTopic)
    if (topic) {
      setTopicChangeDetails(topic)
      setWorkflowTopicV2(!!topic.v2)
      setTopicChangeRequested(true)
    }
  }

  const onCancelChangeTopic = () => {
    setTopicChangeDetails(undefined)
    setTopicChangeRequested(false)
  }

  const onChangeTopic = () => {
    if (topicChangeDetails) {
      updateResult((current) => ({
        ...current,
        newBooking: {
          ...current.newBooking,
          jobTopic: {
            topic: topicChangeDetails,
            actionCompleted: true
          },
          // Ensure we maintain these fields when changing topic
          jobClassification: {
            topic: {
              customerType: current.newBooking?.jobClassification?.topic?.customerType,
              jobDescription: current.newBooking?.jobClassification?.topic?.jobDescription
            }
            // workflowV2: !!topicChangeDetails.v2
          }
        }
      }))
      onCancelChangeTopic()
    }
  }

  const [createNewEnquiry, { loading: resetLoading }] = useCreateEnquiryService<NewEnquiryResult>()

  const handleCreateNewEnquiry = async (customerId?: string) => {
    const initialResult: NewEnquiryResult = {
      contact: {}
    }
    const enquiryId = await createNewEnquiry({ initialResult })
    //context to add interactionMessage.addEnquiry(enquiryId)
    const url = customerId ? `/workflow/${enquiryId}/${customerId}` : `/workflow/${enquiryId}`
    // Push an empty url first to ensure router reloads this component with the new enquiryId
    navigate('/empty')
    navigate(url, { replace: true })
    window.location.reload()
    return
  }

  const handleResetState = async () => {
    console.log('handleResetState')
    setEnquiryNotes('')
    await handleCreateNewEnquiry()
  }

  const handleResetEnquiry = async () => {
    const result = workflowState.result
    // TODO: move customer and contact to a standard part of the workflow state
    let customerId: string | undefined
    switch (workflowState.result.enquiryType?.enquiryType) {
      case EnquiryTypeReference.NewBooking:
        customerId = result.newBooking?.createCustomer?.customer?.id ?? result.newBooking?.customer?.customer?.id
        break
      case EnquiryTypeReference.CancelJob:
        customerId = result.cancelJob?.customer?.customer?.id
        break
      case EnquiryTypeReference.Complaint:
        customerId = result.complaint?.customer?.customer?.id
        break
      case EnquiryTypeReference.Crisis:
        customerId = result.crisis?.customer?.customer?.id
        break
      case EnquiryTypeReference.ETA:
        customerId = result.eta?.customer?.customer?.id
        break
      case EnquiryTypeReference.Feedback:
        customerId = result.feedback?.customer?.customer?.id
        break
      case EnquiryTypeReference.PayInvoice:
        customerId = result.payInvoice?.customer?.customer?.id
        break
      case EnquiryTypeReference.Rebooking:
        customerId = result.rebooking?.customer?.customer?.id
        break
      case EnquiryTypeReference.UpdateJob:
        customerId = result.updateJob?.customer?.customer?.id
        break
      case EnquiryTypeReference.ResendInvoice:
        customerId = result.resendInvoice?.customer?.customer?.id
        break
      case EnquiryTypeReference.UpdateCustomer:
        customerId = result.updateCustomer?.customer?.customer?.id
        break
      case EnquiryTypeReference.Transfer:
        customerId = result.transferCall?.customer?.customer?.id
        break
      case EnquiryTypeReference.Membership:
      case EnquiryTypeReference.DisputeInvoice:
      case EnquiryTypeReference.Employment:
      case EnquiryTypeReference.Media:
      case EnquiryTypeReference.Other:
      default:
        customerId = undefined
    }
    await handleCreateNewEnquiry(customerId)
  }

  const handleActionUpdate = <K extends keyof NewEnquiryResult, T extends BaseActionResult>(key: K, result: T) => {
    //const { customer: customerData } = opts ?? {}

    updateResult((current) => {
      const newResults = { ...current, [key]: result }
      const { customerId: oldCustomerId } = getWorkflowFields(current)
      const { customerId: newCustomerId } = getWorkflowFields(newResults)
      if (oldCustomerId !== newCustomerId) {
        setCustomerIdentifier(newCustomerId)
      }
      return newResults
    })

    if (result.actionCompleted) {
      console.log('action complete')
      addResponse(
        { reference: key, value: result.value ?? 'missing-value', isEnquiryComplete: result.actionCompleted },
        getTrackProperties(workflowState.result)
      )
      handleCompleteEnquiry()
    }
  }

  const getTrackProperties = (result: NewEnquiryResult): Dict => {
    const enquiryType = result.enquiryType?.enquiryType
    const isAdminEnquiry = enquiryType !== EnquiryTypeReference.NewBooking
    const jobTopic = result.newBooking?.jobTopic?.topic?.reference
    return { enquiryType, isAdminEnquiry, jobTopic }
  }

  const handleCompleteEnquiry = () => {
    completeEnquiry(getTrackProperties(workflowState.result))
  }

  const handleContactActionUpdate = (contact: ContactActionResult) => {
    handleActionUpdate('contact', contact)
    if (contact.id) {
      mixpanel?.track('EnquiryContactEntered')
    }
  }

  const handleEnquiryTypeUpdate = (enquiryType: EnquiryTypeActionResult) => {
    handleActionUpdate('enquiryType', enquiryType)
    if (enquiryType.enquiryType) {
      setContactEntryCompleted(true)
      const isAdminEnquiry = enquiryType.enquiryType !== EnquiryTypeReference.NewBooking
      mixpanel?.track('EnquiryTypeSelected', { enquiryType: enquiryType.enquiryType, isAdminEnquiry })
    }
  }

  const onChangeContact = ({ customer, contactId, contactInput }: OnChangeContactProps) => {
    setContact({ contactId, customerId: customer?.id, contactInput })
  }

  const handleAbortEnquiry = (abortDetails: EnquiryAbortDetails) => {
    abortEnquiry(cloneDeep(abortDetails))
    setAbortRequested(false)
    setAbortDetails(undefined)
  }

  const clearEnquiryType = () => clearResponses(['enquiryType', 'rebooking'])

  // look at current state, and determine what should be on screen
  const currentAction = (result: NewEnquiryResult): ReactNode | undefined => {
    const isJobCreated = !!result?.newBooking?.createJob?.job?.id
    const isHPPPurchased = !!result.membership?.renewMembership?.createMembership?.jobId
    const isChangingJobEnquiryDisabled = isJobCreated || isHPPPurchased

    if (workflowState.endDate && workflowState.completionType) {
      return (
        <WorkflowComplete
          completionType={workflowState.completionType}
          firstName={result.contact?.firstName}
          duration={DateTime.fromISO(workflowState.endDate).diff(
            DateTime.fromISO(workflowState.startDate),
            'milliseconds'
          )}
          startNewEnquiry={() => handleResetState()}
          startNewEnquiryType={() => handleResetEnquiry()}
          loading={resetLoading}
        />
      )
    } else {
      return (
        <>
          <EnquiryTypeAction
            input={{ editable: !isChangingJobEnquiryDisabled }}
            result={result.enquiryType}
            onUpdate={handleEnquiryTypeUpdate}
            onBack={() => {
              clearEnquiryType()
              goBack('enquiry-type')
            }}
          />

          {result.enquiryType?.enquiryType && (
            <ActionForEnquiryType
              enquiryType={result.enquiryType.enquiryType}
              result={result}
              handleActionUpdate={handleActionUpdate}
              goBack={goBack}
              completeEnquiry={handleCompleteEnquiry}
              updateEnquiryType={handleEnquiryTypeUpdate}
            />
          )}
        </>
      )
    }
  }

  return (
    <>
      {loading ? (
        <EuiLoadingSpinner />
      ) : (
        <WorkflowProvider
          enquiryId={workflowState.enquiryId}
          onChangeJobTopic={onChangeTopicRequested}
          onAbort={onAbortRequested}
        >
          {webForm ? <EnquiryWebFormView webForm={webForm} /> : <div></div>}
          {editFastTrackBookingState && (
            <>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem grow={false}>
                  <EuiSwitch
                    label="Fast booking script"
                    onChange={(event) => setFastBookingScript?.(event.target.checked)}
                    checked={!!fastTrackBookingEnabled}
                    compressed
                    disabled={disableFastTrackBookingSwitch}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer size="m" />
            </>
          )}
          {abortRequested && abortDetails && (
            <AbortEnquiryAction
              contactId={workflowState.result?.contact?.id} //customer?.contact?.id
              details={{
                ...abortDetails,
                jobTopicReference: workflowState.result?.newBooking?.jobTopic?.topic?.reference
              }}
              onCancel={onCancelAbort}
              onProceed={(abortDetails) => handleAbortEnquiry(abortDetails)}
              onChangeContact={onChangeContact}
            />
          )}
          {topicChangeRequested && topicChangeDetails && (
            <EuiConfirmModal
              title="Change job topic"
              onCancel={onCancelChangeTopic}
              onConfirm={onChangeTopic}
              cancelButtonText="No"
              confirmButtonText="Yes, please"
              defaultFocusedButton="confirm"
            >
              <p>Are you sure you want to change the job topic to {topicChangeDetails.label}?</p>
            </EuiConfirmModal>
          )}
          <EuiFlexGroup alignItems="flexStart">
            <EuiFlexItem grow={false} style={{ width: '300px' }} className="workflow__sidebar">
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiTitle>
                    <h1>New enquiry</h1>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={() => onAbortRequested({})} color="warning" size="s">
                    Abort
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
              {/* {persistence !== EnquiryPersistenceEnum.None && (
                <>
                  <EuiSpacer size="s" />
                  <EuiLink
                    href={`/enquiries/${id}`}
                    style={{ fontSize: '12px' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {enquiry?.reference ?? 'no reference'}
                  </EuiLink>
                  <div>Version: {workflowState.resultVersion}</div>
                </>
              )} */}
              <EuiSpacer />
              <ContactAction
                completed={contactEntryCompleted}
                setCompleted={setContactEntryCompleted}
                contact={contactDetails}
                setContact={setContactDetails}
                input={{}}
                onUpdate={handleContactActionUpdate}
                onBack={() => {
                  goBack('contact')
                }}
              />
              <EuiFormRow label="Enquiry notes">
                <EuiTextArea
                  style={{ minHeight: '340px' }}
                  value={enquiryNotes}
                  onChange={(e) => setEnquiryNotes(e.target.value)}
                />
              </EuiFormRow>
              <RawJSON data={workflowState} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel>
                <div>
                  <div data-test-id="bouncing-ball-workflow">{currentAction(workflowState.result)}</div>
                </div>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="xxl" />
        </WorkflowProvider>
      )}
    </>
  )
}
