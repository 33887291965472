import {
  CallOutSubmitWorkflowStepDetailInput,
  CallOutType,
  CallOutWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { Callout, CalloutType } from '../../common/callout'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type CallOutWorkflowV2StepProps = WorkflowV2StepProps<
  CallOutWorkflowStepDetailFragment,
  CallOutSubmitWorkflowStepDetailInput
>

export const CallOutWorkflowV2Step = ({ detail }: CallOutWorkflowV2StepProps) => {
  const { title, description, callOutType } = detail

  const typeString = getCallOutTypeString(callOutType)

  return (
    <Callout title={title} type={typeString}>
      {description}
    </Callout>
  )
}

const getCallOutTypeString = (type: CallOutType): CalloutType => {
  switch (type) {
    case CallOutType.Warning:
      return 'warning'
    case CallOutType.Info:
      return 'note'
    case CallOutType.Success:
      return 'success'
    case CallOutType.Error:
      return 'failure'
  }
}
