import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../../topics/common/common-enums'
import { BookingWrapUpActionResult } from './action-booking-wrapup'

export enum CustomerDogQuestionState {
  unknown = 'unknown',
  Bookable = 'Bookable',
  Unbookable = 'Unbookable'
}

export interface CustomerDogQuestionProps {
  onUpdate: (result: BookingWrapUpActionResult) => void
  result?: BookingWrapUpActionResult
  doYouHaveADog?: YesNoValue
  canYouRestrainYourDog?: YesNoValue
  safetyFollowUp?: YesNoValue
}
export const CustomerDogQuestion = ({
  result,
  onUpdate,
  doYouHaveADog,
  canYouRestrainYourDog,
  safetyFollowUp
}: CustomerDogQuestionProps) => {
  return (
    <>
      <SingleSelectField
        question="Just quickly, are there any dogs at the property?"
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes',
            icon: 'check'
          },
          {
            id: YesNoValue.No,
            label: 'No',
            icon: 'cross'
          }
        ]}
        answer={doYouHaveADog}
        changeAnswer={(doYouHaveADog) =>
          onUpdate({
            ...result,
            doYouHaveADog
          })
        }
      />
      {doYouHaveADog === YesNoValue.Yes && (
        <SingleSelectField
          question="To keep everyone safe and focused, we kindly ask that dogs are kept in a separate area during out visit, is that okay?"
          options={[
            {
              id: YesNoValue.Yes,
              label: 'Yes',
              icon: 'check'
            },
            {
              id: YesNoValue.No,
              label: 'No',
              icon: 'cross'
            }
          ]}
          answer={canYouRestrainYourDog}
          changeAnswer={(canYouRestrainYourDog) =>
            onUpdate({
              ...result,
              canYouRestrainYourDog
            })
          }
        />
      )}
      {canYouRestrainYourDog === YesNoValue.No && (
        <>
          <SingleSelectField
            question="We love dogs, but current legislation requires us to provide a safe work environment, which means keeping dogs in a separate area. Is that okay?"
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={safetyFollowUp}
            changeAnswer={(safetyFollowUp) =>
              onUpdate({
                ...result,
                safetyFollowUp
              })
            }
          />
        </>
      )}
    </>
  )
}
