import {
  AnnotationDomainType,
  AreaSeries,
  Axis,
  BarSeries,
  Chart,
  CurveType,
  LIGHT_THEME,
  LineAnnotation,
  LineSeries,
  LineSeriesStyle,
  RecursivePartial,
  ScaleType,
  SeriesColorAccessor,
  Settings,
  timeFormatter
} from '@elastic/charts'
import '@elastic/charts/dist/theme_only_light.css'
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import { filter, isNil } from 'lodash-es'
import { Moment } from 'moment-timezone'
import { ReactNode, useMemo } from 'react'
import { DateHistogramInterval } from '../api/generated-types'
import createPersistedState from '../use-persisted-state'

const useChartHeight = createPersistedState<ChartHeight>('chart-height')

interface ChartProps {
  renderHeader?: () => ReactNode
  interval: DateHistogramInterval
  setInterval: (interval: DateHistogramInterval) => void
  startDate: Moment
  endDate: Moment
  data: ChartData
  showZeroLine?: boolean
}

export interface ChartData {
  name: string
  series: ChartDataSeries[]
  interval: string
}

export enum ChartAxisType {
  Money,
  Count
}

export enum ChartSeriesDisplayType {
  Line,
  Bar,
  Area
}

export interface ChartDataSeries {
  id: string
  name: string
  data: ChartDataPoint[]
  axisType: ChartAxisType
  xScaleType?: ScaleType | undefined
  displayType?: ChartSeriesDisplayType | undefined
  stackedGroup?: number
  curve?: CurveType
  lineSeriesStyle?: RecursivePartial<LineSeriesStyle>
  color?: SeriesColorAccessor
}

export interface ChartDataPoint {
  date: number // unix timestamp
  value: number
}

enum ChartHeight {
  Regular = 200,
  Expanded = 500
}

export const HistogramChart = (props: ChartProps) => {
  const [chartHeight, setChartHeight] = useChartHeight(ChartHeight.Regular)

  const { data, interval, setInterval, showZeroLine, renderHeader } = props

  const chart = useMemo(() => {
    const euiTheme = LIGHT_THEME
    const countFormatter = (d: number) => d?.toLocaleString() ?? ''
    const dateFormat = dateFormatForInterval(interval)
    const dateFormatter = timeFormatter(dateFormat)
    const moneyFormatter = (d: number) => `$${MoneyUtils.fromNumber(d).toFormat('0,0')}`

    const hasMoneyAxis = filter(data.series, (series) => series.axisType === ChartAxisType.Money).length
    const hasCountAxis = filter(data.series, (series) => series.axisType === ChartAxisType.Count).length

    const toggleChartHeight = () =>
      setChartHeight(chartHeight === ChartHeight.Regular ? ChartHeight.Expanded : ChartHeight.Regular)

    const renderSeries = (series: any) => {
      const xScaleType = series.xScaleType ?? ScaleType.Time
      switch (series.displayType) {
        case ChartSeriesDisplayType.Bar:
          return (
            <BarSeries
              id={series.id}
              key={series.id}
              groupId={series.axisType === ChartAxisType.Money ? 'money' : 'count'}
              name={series.name}
              data={series.data}
              color={series.color}
              xAccessor={'date'}
              yAccessors={['value']}
              stackAccessors={!isNil(series.stackedGroup) ? [series.stackedGroup] : undefined}
              xScaleType={xScaleType}
            />
          )
        case ChartSeriesDisplayType.Area:
          return (
            <AreaSeries
              id={series.id}
              key={series.id}
              groupId={series.axisType === ChartAxisType.Money ? 'money' : 'count'}
              name={series.name}
              data={series.data}
              color={series.color}
              xAccessor={'date'}
              yAccessors={['value']}
              curve={series.curve ?? CurveType.LINEAR}
              areaSeriesStyle={{
                point: {
                  visible: 'always'
                },
                line: {
                  strokeWidth: 1,
                  opacity: 0.8
                }
              }}
              stackAccessors={!isNil(series.stackedGroup) ? [series.stackedGroup] : undefined}
              xScaleType={xScaleType}
            />
          )
        case ChartSeriesDisplayType.Line:
        default:
          return (
            <LineSeries
              id={series.id}
              key={series.id}
              groupId={series.axisType === ChartAxisType.Money ? 'money' : 'count'}
              name={series.name}
              data={series.data}
              color={series.color}
              curve={series.curve ?? CurveType.LINEAR}
              xAccessor={'date'}
              yAccessors={['value']}
              lineSeriesStyle={series.lineSeriesStyle}
              xScaleType={xScaleType}
            />
          )
      }
    }

    // FIXME: elastic charts types need updating to remove below ts-expect-error
    return (
      <div style={{ background: 'white', padding: '6px', borderRadius: '6px' }}>
        <EuiFlexGroup gutterSize="none">
          {renderHeader ? <EuiFlexItem grow={false}>{renderHeader()}</EuiFlexItem> : null}
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType={chartHeight === ChartHeight.Regular ? 'expand' : 'minimize'}
              onClick={() => toggleChartHeight()}
              size="xs"
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <Chart size={{ height: chartHeight }}>
          <Settings theme={euiTheme} />
          {showZeroLine && (
            <LineAnnotation
              id="zero-line"
              groupId={hasMoneyAxis ? 'money' : 'count'}
              dataValues={[{ dataValue: 0, details: 'Zero' }]}
              domainType={AnnotationDomainType.YDomain}
              style={{
                line: {
                  stroke: '#525B72',
                  strokeWidth: 1,
                  opacity: 0.7,
                  dash: [4, 4]
                }
              }}
            />
          )}
          {data.series.map((series) => renderSeries(series))}
          <Axis
            id="bottom-axis"
            position="bottom"
            tickFormat={dateFormatter}
            labelFormat={dateFormatter}
            groupId={hasMoneyAxis ? 'money' : 'count'}
          />
          {hasCountAxis && (
            <Axis
              id="count-axis"
              position="left"
              groupId="count"
              tickFormat={countFormatter}
              labelFormat={countFormatter}
            />
          )}
          {hasMoneyAxis && (
            <Axis
              id="money-axis"
              position="right"
              groupId="money"
              tickFormat={moneyFormatter}
              labelFormat={moneyFormatter}
            />
          )}
        </Chart>
        {/* <EuiButtonGroup
          legend="Interval"
          options={intervalOptions}
          idSelected={interval}
          onChange={(id) => onChangeInterval(id)}
          buttonSize="compressed"
        /> */}
      </div>
    )
  }, [data, interval, setInterval, chartHeight, setChartHeight])

  return chart
}

export const dateFormatForInterval = (interval: DateHistogramInterval): string => {
  switch (interval) {
    case DateHistogramInterval.Hour:
      return 'ha, MMM DD'
    case DateHistogramInterval.Day:
      return 'MMM DD'
    case DateHistogramInterval.Week:
      return 'MMM DD'
    case DateHistogramInterval.Month:
      return 'MMM YYYY'
    case DateHistogramInterval.Year:
      return 'YYYY'
    case DateHistogramInterval.Auto:
    default:
      return 'MMMM DD'
  }
}
