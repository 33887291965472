import { EuiLoadingSpinner } from '@elastic/eui'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import Signout from '../auth/signout'

const SignIn = lazy(() => import('../auth/signin'))
const AuthCallback = lazy(() => import('../auth/authentication-callback'))
const SSORoutes = lazy(() => import('../sso/sso-routes'))

const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/launch" element={<Navigate replace to="fs://welcome" />} />
        <Route path="/sso/*" element={<SSORoutes />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="*" element={<SignIn />} />
      </Routes>
    </Suspense>
  )
}

export default UnauthenticatedRoutes
