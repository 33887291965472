import { EuiButtonIcon, EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiSelect } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import { cloneDeep, isNull } from 'lodash-es'
import { useState } from 'react'
import { PaymentMethod, UserAuthFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { LocalPayment } from './invoice-data/create-invoice-data'

interface CreatePaymentRowProps {
  payment: LocalPayment
  removable?: boolean
  onChangePayment: (payment: LocalPayment) => void
  onRemovePayment?: (paymentId: string) => void
  onBeginEditing?: (event: any) => void
}

const methodLabel = (method: PaymentMethod | string): string => {
  switch (method as PaymentMethod) {
    case PaymentMethod.DebitCard:
      return 'Debit card'
    case PaymentMethod.CreditCard:
      return 'Credit card'
    default:
      return method
  }
}

const restrictedPaymentMethodAvailable = (user: UserAuthFragment | undefined) =>
  user?.permissions?.restrictedPaymentMethods === true
const paymentMethodOptions = Object.keys(PaymentMethod).map((method) => {
  switch (method) {
    case PaymentMethod.StaffDeduction:
      return {
        value: method,
        text: methodLabel(method),
        isAvailable: restrictedPaymentMethodAvailable
      }
    default:
      return {
        value: method,
        text: methodLabel(method)
      }
  }
})

export const CreateInvoicePaymentRow = (props: CreatePaymentRowProps) => {
  const { onChangePayment, onRemovePayment, payment } = props
  const onBeginEditing = props.onBeginEditing ?? (() => true)
  const removable = props.removable ?? true
  const userFragment = useAuthenticated()?.userFragment

  const [amount, setAmount] = useState(payment.amount?.toFormat('0.00') ?? '0.00')
  const [method, setMethod] = useState(payment.method)

  const methodOptions = paymentMethodOptions.filter((option) => {
    if (option.isAvailable) {
      return option.isAvailable(userFragment)
    }
    return true
  })

  const onChangeAmount = (value: string) => {
    setAmount(value)
    const newAmount = convertAmount(value)
    const updatedPayment = cloneDeep(payment)
    updatedPayment.amount = newAmount
    onChangePayment(updatedPayment)
  }

  const onChangeMethod = (e: any) => {
    const newMethod = e.target.value as PaymentMethod
    setMethod(newMethod)
    const updatedPayment = cloneDeep(payment)
    updatedPayment.method = newMethod
    onChangePayment(updatedPayment)
  }

  const convertAmount = (value: string) => {
    try {
      const money = MoneyUtils.fromString(value)
      return money
    } catch (err) {
      console.error(err)
      return MoneyUtils.fromString('0.00')
    }
  }

  const removePayment = () => {
    if (onRemovePayment) {
      onRemovePayment(payment.id)
    }
  }

  return (
    <EuiFlexGroup alignItems="center">
      <EuiFlexItem grow={true} style={{ textAlign: 'left' }}>
        Payment
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiSelect options={methodOptions} value={method} onChange={onChangeMethod} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFieldNumber
          placeholder="Enter amount"
          value={amount === '0.00' ? '' : amount}
          onChange={(e) => onChangeAmount(e.target.value)}
          isInvalid={isNull(amount)}
          style={{ width: '140px', textAlign: 'left' }}
          onKeyDown={onBeginEditing}
          step="any"
          min={0}
          max={220000}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          color="text"
          onClick={removePayment}
          iconType="cross"
          aria-label="Remove line item"
          onKeyDown={onBeginEditing}
          disabled={!removable}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
