import { EuiBreadcrumbs, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiText } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import {
  SubtopicLinkFragment,
  TopicLinkFragment,
  TradeLinkFragment,
  WorkflowOutcomeFragment
} from '@fallonsolutions/types'
import { compact } from 'lodash-es'
import { formatMoney } from '../../common/money'
import VerticalDivider from '../../common/vertical-divider'
import { PriorityIcon } from '../../priority/priority-icon'
import '../../static/css/workflow-outcome.css'
import { Tag, TagSize } from '../../tags/tag'

export interface WorkflowOutcomeViewProps {
  outcome: WorkflowOutcomeFragment
  showPanel?: boolean
  display?: 'vertical' | 'horizontal'
}

export const WorkflowOutcomeView = ({ outcome, ...props }: WorkflowOutcomeViewProps) => {
  const display = props.display ?? 'vertical'
  const showPanel = props.showPanel ?? false
  const topicLabel = outcome.subtopic?.name ?? outcome.topic?.name ?? outcome.trade?.name ?? 'Unknown'
  const serviceTypeLabel = outcome.serviceType?.name
  const label = [topicLabel, serviceTypeLabel].filter(Boolean).join(' - ')
  const breadcrumbs = compact([outcome.trade?.name, outcome.topic?.name, outcome.subtopic?.name])
    .slice(0, -1)
    .map((text) => ({ text }))
  const travelFeeLabel = outcome.travelFee ? outcome.travelFee.name : ''
  const travelFeeAmountString = outcome.travelFee
    ? formatMoney(MoneyUtils.fromString(outcome.travelFee.amount.amount), { currencySymbol: true })
    : 0
  const tags = outcome.tags ?? []
  const iconUrl = outcome.serviceType?.iconUrl

  return (
    <EuiFlexGroup
      className={`workflow-outcome ${showPanel ? 'workflow-outcome--panel' : ''}`}
      direction={display === 'vertical' ? 'column' : 'row'}
      alignItems={display === 'vertical' ? 'stretch' : 'flexEnd'}
      gutterSize="none"
      // style={display === 'vertical' ? { maxWidth: '320px' } : {}}
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup gutterSize="m" alignItems="center">
          {iconUrl && (
            <EuiFlexItem grow={false}>
              <div
                style={{
                  background: '#F3F4F8',
                  borderRadius: '4px',
                  width: '36px',
                  height: '36px',
                  textAlign: 'center',
                  lineHeight: '32px'
                }}
              >
                <EuiIcon type={iconUrl} size="xl" color="#8b91a0" style={{ margin: 'auto' }} />
              </div>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={true}>
            <>
              <EuiFlexGroup alignItems="baseline">
                <EuiFlexItem grow={false}>
                  <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={true} />
                </EuiFlexItem>
                <EuiFlexItem grow={true} />
                <EuiFlexItem grow={false}>
                  <PriorityIcon priority={outcome.priority} />
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiText size="m" style={{ fontWeight: 500, marginTop: '-2px' }}>
                {label}
              </EuiText>
            </>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {display === 'vertical' ? (
          <EuiHorizontalRule margin="s" />
        ) : (
          <div style={{ padding: '0 12px' }}>
            <VerticalDivider height="44px" />
          </div>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="baseline" gutterSize="s">
          <EuiFlexItem grow={false}>{travelFeeAmountString}</EuiFlexItem>
          <EuiFlexItem grow={false}>{travelFeeLabel}</EuiFlexItem>
          <EuiFlexItem grow={true} />
          {tags.length > 0 && (
            <EuiFlexItem grow={false}>
              {tags.map((tag) => (
                <Tag key={tag.id} tag={tag} size={TagSize.Compact} />
              ))}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

interface TopicLabelProps {
  trade?: TradeLinkFragment
  topic?: TopicLinkFragment
  subtopic?: SubtopicLinkFragment
}

const TopicLabel = ({ trade, topic, subtopic }: TopicLabelProps) => {
  const values = compact([trade?.name, topic?.name, subtopic?.name])

  if (values.length === 0) {
    return <>None</>
  } else if (values.length === 1) {
    return <>{values[0]}</>
  } else {
    const breadcrumbs = values.slice(0, values.length - 1).join(' > ')
    return (
      <>
        <div style={{ fontSize: '11px' }}>{breadcrumbs}</div>
        <div>{values[values.length - 1]}</div>
      </>
    )
  }
}
