import { AuthenticatedContext, createAuthenticatedContext } from '../auth/authenticated-context'
import { AuthorizationLoaderContext, useAuthorization } from '../auth/authorization-context'
import { SessionLoaderContext, useSession } from '../auth/session-context'
import { Callout } from '../common/callout'
import { AppConfigLoaderProvider } from '../config/app-config-loader-provider'
import { ContactSearchProvider } from '../contacts/contact-search/contact-search-provider'
import { InteractionServiceProvider } from '../interactions/interaction-service'
import { LoadingError } from '../loading/loading-error'
import { LoadingSpinner } from '../loading/loading-spinner'
import { MapsAPI } from '../maps/maps-api-wrapper'
import { MediaServiceProvider } from '../media/media-service'
import { MobileAppLauncher } from '../mobile-app/mobile-app-launcher'
import Header from '../nav/header'
import CrispWidget from './crisp'
import { NotificationProvider } from './notification-provider'
import { RiskAssessmentSubscription } from './risk-assessment-subscription'
import AuthenticatedRoutes from './routes-authenticated'

enum LoadingState {
  AuthenticationLoading = 'AuthenticationLoading',
  AuthenticationError = 'AuthenticationError',
  AuthorizationError = 'AuthorizationError',
  AuthorisationLoading = 'AuthorisationLoading',
  Authenticated = 'Authenticated',
  Unauthenticated = 'Unauthenticated'
}

const AuthenticatedRoutesWrapper = () => {
  const session = useSession() // Rename to authentication?
  const authorization = useAuthorization()
  const loadingState = getLoadingState({ session, authorization })

  const loadingSpinner = (message: string) => <LoadingSpinner message={message} style={{ marginTop: '200px' }} />

  const renderView = () => {
    console.log('authenticated-routes-wrapper loading-state', loadingState)
    switch (loadingState) {
      case LoadingState.Unauthenticated:
        return (
          <div>
            <Callout type="failure" title="Should not be able to see this" />
          </div>
        )
      case LoadingState.Authenticated:
        if (
          !session.data?.user ||
          !authorization.data?.userFragment ||
          !authorization.data?.userAttributes ||
          !authorization.data?.iamUtils?.ready
        ) {
          return <>Error, no user found</>
        }
        return (
          <AuthenticatedContext.Provider
            value={createAuthenticatedContext({
              session,
              authorization
            })}
          >
            <AppConfigLoaderProvider>
              <MediaServiceProvider>
                <NotificationProvider>
                  <InteractionServiceProvider>
                    <ContactSearchProvider>
                      <div>
                        <CrispWidget />
                        <Header />
                        <RiskAssessmentSubscription />
                      </div>
                      <div className="page-wrapper">
                        <AuthenticatedRoutes />
                        <MobileAppLauncher />
                      </div>
                      <MapsAPI />
                    </ContactSearchProvider>
                  </InteractionServiceProvider>
                </NotificationProvider>
              </MediaServiceProvider>
            </AppConfigLoaderProvider>
          </AuthenticatedContext.Provider>
        )
      case LoadingState.AuthenticationLoading:
        return loadingSpinner('Authenticating')
      case LoadingState.AuthorisationLoading:
        return loadingSpinner('Authorizing')
      case LoadingState.AuthenticationError:
        console.log(`page-wrapper: ${session.error}`)
        return <LoadingError message="There was a problem authenticating" retryClick={session.retry} />
      case LoadingState.AuthorizationError:
        console.log(`page-wrapper: ${authorization.error}`)
        return <LoadingError message="There was a problem authorising" retryClick={authorization.retry} />
    }
  }
  return <div className="app">{renderView()}</div>
}

interface GetSessionStatusProps {
  session: SessionLoaderContext
  authorization: AuthorizationLoaderContext
}

const getLoadingState = ({ session, authorization }: GetSessionStatusProps): LoadingState => {
  if (session.error) {
    return LoadingState.AuthenticationError
  } else if (authorization.error) {
    return LoadingState.AuthorizationError
  } else if (session.loading) {
    return LoadingState.AuthenticationLoading
  } else if (authorization.loading) {
    return LoadingState.AuthorisationLoading
  } else if (session.loaded && !!authorization.data?.userFragment) {
    return LoadingState.Authenticated
  } else {
    return LoadingState.Unauthenticated
  }
}

export default AuthenticatedRoutesWrapper
