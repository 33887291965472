import { EuiCopy } from '@elastic/eui'
import { useApp } from '../app/app-context'

interface AppVersionLabelProps {
  style?: React.CSSProperties
}

export const AppVersionLabel = (props: AppVersionLabelProps) => {
  const appContext = useApp()

  const style = props.style ?? {}
  const versionString = `App version ${appContext.buildVersion ?? 'unknown'}`
  return (
    <EuiCopy textToCopy={versionString}>
      {(copy) => (
        <span
          style={{ ...style, fontSize: '12px', color: '#ACAFB9', cursor: 'pointer', display: 'inline-block' }}
          onClick={copy}
        >
          {versionString}
        </span>
      )}
    </EuiCopy>
  )
}
