import { EuiProvider } from '@elastic/eui'
import { Global } from '@emotion/react'
import { DateTime } from 'luxon'
import createPersistedState from '../use-persisted-state'

import createCache from '@emotion/cache'

enum Theme {
  Light = 'light',
  Dark = 'dark',
  Auto = 'auto'
}

const useTheme = createPersistedState<Theme>('theme')

interface ThemeSelectorProps {
  children: React.ReactNode
}

const ThemeSelector = (props: ThemeSelectorProps) => {
  const [theme] = useTheme(Theme.Light)

  let currentTheme: Theme
  switch (theme) {
    case Theme.Light:
      currentTheme = Theme.Light
      break
    case Theme.Dark:
      currentTheme = Theme.Dark
      break
    case Theme.Auto: {
      const hour = DateTime.local().hour
      currentTheme = hour >= 5 && hour <= 19 ? Theme.Light : Theme.Dark
      break
    }
    default:
      currentTheme = Theme.Light
  }

  const themeClass = currentTheme === Theme.Dark ? 'theme-dark' : 'theme-light'

  // https://github.com/elastic/eui/pull/5920
  const euiCache = createCache({
    key: 'eui',
    container: document.querySelector('meta[name="eui-style-insert"]') as any
  })
  euiCache.compat = true

  // Global styles used to remove black outline set by EUI: https://github.com/elastic/eui/issues/5436
  return (
    <EuiProvider colorMode={currentTheme} cache={euiCache}>
      <Global
        styles={{
          '*:focus:focus-visible': {
            outlineStyle: 'none'
          }
        }}
      />
      <div className={themeClass}>{props.children}</div>
    </EuiProvider>
  )
}

export default ThemeSelector
