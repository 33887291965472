import { useApolloClient, useMutation, useSubscription } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiGlobalToastList, EuiLink, EuiText } from '@elastic/eui'
// import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  NotificationChannel,
  NotificationFragmentDoc,
  NotificationMutationType,
  NotificationStatusType,
  OnNotificationEventDocument,
  UpdateNotificationStatusDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { MediaItemGallery } from '../media/media-item-gallery'
import { MediaItemGroupView } from '../media/media-item-group'

type Toast = any // TODO: fix elasticui import

const bannerToastLifetime = 6000
const alertToastLifetime = 60000

export interface INotificationProviderContext {
  createToast: (toast: Toast) => void
  removeToast: (toastId: string) => void
}

export const NotificationProviderContext = React.createContext<INotificationProviderContext>({
  createToast: () => true,
  removeToast: () => true
})

export const useNotification = () => React.useContext(NotificationProviderContext)

interface NotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider = (props: NotificationProviderProps) => {
  const client = useApolloClient()
  const [toasts, setToasts] = useState<Toast[]>([])
  const session = useAuthenticated().user

  const { tenantId, id: userId } = session

  const onCreateToast = (toast: Toast) => setToasts((toasts) => [...toasts, toast])
  const onRemoveToast = (toast: Toast) => setToasts((toasts) => toasts.filter((t) => t.id !== toast.id))

  const removeToast = (toastId: string) => setToasts((toasts) => toasts.filter((t) => t.id !== toastId))

  const [updateNotificationStatus] = useMutation(UpdateNotificationStatusDocument, {
    refetchQueries: ['SearchNotifications']
  })

  const changeStatus = (notificationId: string, status: NotificationStatusType) => {
    updateNotificationStatus({
      variables: {
        input: {
          notificationId,
          status
        }
      },
      optimisticResponse: (vars, { IGNORE }) => {
        const notificationFragment = client.readFragment({
          id: `Notification:${vars.input.notificationId}`,
          fragment: NotificationFragmentDoc,
          fragmentName: 'Notification'
        })
        if (!notificationFragment) {
          console.log('optimisticResponse: no notificationFragment')
          return IGNORE as any
        }
        return {
          updateNotificationStatus: {
            __typename: 'AnyNotificationPayload',
            tenantId: notificationFragment.tenantId,
            kind: NotificationMutationType.UpdateNotificationStatus,
            userId: notificationFragment.userId,
            notification: {
              ...notificationFragment,
              status: {
                ...notificationFragment.status,
                status
              }
            }
          }
        }
      }
    })
  }

  const gotoLink = (notificationId: string, url: string) => {
    changeStatus(notificationId, NotificationStatusType.Read)
    removeToast(notificationId)
    window.open(url, '_blank')
  }

  useSubscription(OnNotificationEventDocument, {
    variables: { tenantId, userId },
    onData: (data) => {
      const notification = data.data.data?.onNotificationEvent?.notification

      if (!notification) {
        console.log('no notification')
        return
      }
      const isAlert = notification.channels.includes(NotificationChannel.Alert)
      const isBanner = notification.channels.includes(NotificationChannel.Banner)
      if (!isAlert && !isBanner) {
        console.log('notification.channels does not include alert or banner', notification.channels)
        return
      }

      const attachments = notification?.attachments ?? []
      if (notification?.status.status === NotificationStatusType.Unread) {
        onCreateToast({
          id: notification.id,
          title: notification.subject,
          text: (
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={true}>
                <EuiFlexGroup direction="column" gutterSize="s">
                  {notification.body !== notification.subject && (
                    <EuiFlexItem grow={false}>
                      <EuiText size="s" color="subdued" className="notification-body">
                        <ReactMarkdown>{notification.body}</ReactMarkdown>
                      </EuiText>
                    </EuiFlexItem>
                  )}
                  {notification.link && (
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={false}>
                          <EuiLink
                            onClick={() => notification.link && gotoLink(notification.id, notification.link.url)}
                            target="_blank"
                            style={{ fontWeight: 400, marginTop: '3px' }}
                          >
                            {notification.link.label}
                          </EuiLink>
                        </EuiFlexItem>
                        <EuiFlexItem grow={true} />
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>
              {attachments.length > 0 && (
                <EuiFlexItem grow={false} style={{ paddingBottom: '3px' }}>
                  <MediaItemGallery>
                    <MediaItemGroupView
                      group={{
                        key: notification.id,
                        stacks: [{ mediaItems: attachments.map((a) => a.mediaItem) }]
                      }}
                      itemWidth={64}
                      uploadEnabled={false}
                      showJobLink={false}
                    />
                  </MediaItemGallery>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          ),
          color: 'primary',
          iconType: 'bell',
          toastLifeTimeMs: isAlert ? alertToastLifetime : bannerToastLifetime
        })
      }
    }
  })

  return (
    <NotificationProviderContext.Provider value={{ createToast: onCreateToast, removeToast }}>
      {props.children}
      <EuiGlobalToastList toasts={toasts} dismissToast={onRemoveToast} toastLifeTimeMs={bannerToastLifetime} />
    </NotificationProviderContext.Provider>
  )
}
