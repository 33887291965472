import { AttributeRequirement, BookingWorkflowOutcomeFragment, TradeType } from '@fallonsolutions/types'

//topic:trade : attributes
//initial skills mapping for solar for backwards compat
interface FromWorkflowV2OutcomeResponse {
  attributes: AttributeRequirement[]
}

export const fromWorkflowV2Outcome = (
  tradeType: TradeType,
  { topic, serviceType, subtopic, tags }: BookingWorkflowOutcomeFragment
): FromWorkflowV2OutcomeResponse => {
  const enabledTrades = [TradeType.Solar, TradeType.Data, TradeType.Security]

  console.log('fromWorkflowV2', topic, serviceType, subtopic, tags)
  if (!enabledTrades.includes(tradeType)) {
    console.error('Missing skills config, please update')
    return { attributes: [] }
  }

  //Data, only 2 skills
  const dataTVAndAntenna = { attributeId: 'TVAndAntenna' }
  const dataPhone = { attributeId: 'PhoneAndData' }

  if (tradeType === TradeType.Data) {
    if (topic?.reference?.toLowerCase() === 'phone-data-point') {
      return { attributes: [dataPhone] }
    } else {
      return { attributes: [dataTVAndAntenna] }
    }
  }

  //Security, only 6 skills
  if (tradeType === TradeType.Security) {
    switch (topic?.reference.toLowerCase()) {
      case 'other':
        return { attributes: [] }
      case 'security-monitoring':
        return { attributes: [{ attributeId: 'Monitoring' }] }
      case 'cctv':
        return { attributes: [{ attributeId: 'CCTV' }] }
      case 'access-control':
        return { attributes: [{ attributeId: 'AccessControl' }] }
      case 'alarm':
        return { attributes: [{ attributeId: 'Alarm' }] }
      case 'intercom':
        return { attributes: [{ attributeId: 'Intercom' }] }
      case 'automation':
        return { attributes: [{ attributeId: 'Automation' }] }
      default:
        return { attributes: [] }
    }
  }

  //Solar
  if (topic?.reference === 'solar') {
    if (subtopic?.reference?.toLowerCase()?.includes('battery')) {
      return { attributes: [{ attributeId: 'SolarHybridOrBattery' }] }
    }
    switch (serviceType?.reference.toLowerCase()) {
      case 'repair':
        return { attributes: [{ attributeId: 'SolarFaultOrRepair' }] }
      case 'inspection':
        return { attributes: [{ attributeId: 'SolarInspection' }] }
      case 'install':
        return { attributes: [{ attributeId: 'SolarQuote' }] }
    }
  }

  return { attributes: [] }
}
