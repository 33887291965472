import { EuiLoadingSpinner } from '@elastic/eui'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { useAuthenticated } from '../auth/authenticated-context'
import Signout from '../auth/signout'
import InteractionMessageRoutes from '../interactions/interaction-message-routes'
import { UnauthorizedError } from './unauthorized-error'

const ActivityRoutes = lazy(() => import('../activities/activity-routes'))
const AppointmentRoutes = lazy(() => import('../appointments/appointment-routes'))
const AttributeRoutes = lazy(() => import('../attributes/attribute-routes'))
const AuthCallback = lazy(() => import('../auth/authentication-callback'))
const BookingConfigRoutes = lazy(() => import('../booking-config/booking-config-routes'))
const BudgetRoutes = lazy(() => import('../budgets/budget-routes'))
const CallCentreRoutes = lazy(() => import('../call-centre/call-centre-routes'))
const ChangelogRoutes = lazy(() => import('../changelog/changelog-routes'))
const CommentRoutes = lazy(() => import('../comments/comment-routes'))
const ComplaintRoutes = lazy(() => import('../complaints/complaint-routes'))
const ContactRoutes = lazy(() => import('../contacts/contact-routes'))
const CostRoutes = lazy(() => import('../costs/cost-routes'))
const CustomerLocationRoutes = lazy(() => import('../customer-locations/customer-location-routes'))
const CustomerRoutes = lazy(() => import('../customers/customer-routes'))
const DiscountRoutes = lazy(() => import('../discounts/discount-routes'))
const DispatchRoutes = lazy(() => import('../dispatch/dispatch-routes'))
const DocumentationRoutes = lazy(() => import('../documentation/documentation-routes'))
const DownloadRoutes = lazy(() => import('../downloads/download-routes'))
const EnquiryRoutes = lazy(() => import('../enquiries/enquiry-routes'))
const ExportRoutes = lazy(() => import('../exports/export-routes'))
const FinanceRoutes = lazy(() => import('../finance/finance-routes'))
const GlossaryRoutes = lazy(() => import('../glossary/glossary-routes'))
const HealthRoutes = lazy(() => import('../health/health-routes'))
const HomeContainer = lazy(() => import('../home/home-container'))
const InspectionRoutes = lazy(() => import('../inspections/inspection-routes'))
const InvoiceRoutes = lazy(() => import('../invoices/invoice-routes'))
const ItemRoutes = lazy(() => import('../pricelist/item-routes'))
const JobReportRoutes = lazy(() => import('../job-reports/job-report-routes'))
const JobRoutes = lazy(() => import('../jobs/job-routes'))
const JobTypeRoutes = lazy(() => import('../job-types/job-type-routes'))
const LabourRateRoutes = lazy(() => import('../labour-rates/labour-rate-routes'))
const LeaderboardRoutes = lazy(() => import('../leaderboards/leaderboard-routes'))
const MarketingRoutes = lazy(() => import('../marketing/marketing-routes'))
const MediaRoutes = lazy(() => import('../media/media-routes'))
const MembershipRoutes = lazy(() => import('../memberships/membership-routes'))
const NotificationRoutes = lazy(() => import('../notifications/notification-routes'))
const OptionSheetTemplateRoutes = lazy(() => import('../option-sheet-templates/option-sheet-template-routes'))
const PartRoutes = lazy(() => import('../parts/part-routes'))
const PaymentRoutes = lazy(() => import('../payments/payment-routes'))
const PerformanceRoutes = lazy(() => import('../performance/performance-routes'))
const PhonebookRoutes = lazy(() => import('../phonebook/phonebook-routes'))
const PricelistRoutes = lazy(() => import('../pricelist/pricelist-routes'))
const PropertyRoutes = lazy(() => import('../properties/property-routes'))
const PublicHolidayRoutes = lazy(() => import('../public-holidays/public-holiday-routes'))
const ReportRoutes = lazy(() => import('../reports/report-routes'))
const ReviewRoutes = lazy(() => import('../reviews/review-routes'))
const RiskAssessmentRoutes = lazy(() => import('../risk-assessment/risk-assessment-routes'))
const SalesCategoryRoutes = lazy(() => import('../sales-categories/sales-category-routes'))
const SalesEventRoutes = lazy(() => import('../sales-events/sales-event-routes'))
const ScheduledServiceRoutes = lazy(() => import('../scheduled-service/scheduled-service-routes'))
const ScheduleEventContainer = lazy(() => import('../schedule/schedule-event-container'))
const ScheduleRoutes = lazy(() => import('../schedule/schedule-routes'))
const ScheduleSlotRoutes = lazy(() => import('../schedule-slots/schedule-slot-routes'))
const ServiceAreaRoutes = lazy(() => import('../service-area/service-area-routes'))
const SettingsRoutes = lazy(() => import('../settings/settings-routes'))
const SSORoutes = lazy(() => import('../sso/sso-routes'))
const TeamRoutes = lazy(() => import('../teams/team-routes'))
const TenantConfigRoutes = lazy(() => import('../tenant-config/tenant-config-routes'))
const TimesheetRoutes = lazy(() => import('../timesheets/timesheet-routes'))
const TransactionRoutes = lazy(() => import('../transactions/transaction-routes'))
const UserRoutes = lazy(() => import('../users/user-routes'))
const WorkflowRoutes = lazy(() => import('../workflow/workflow-routes'))
const WorkflowV2Routes = lazy(() => import('../workflow-v2/workflow-v2-routes'))

const AuthenticatedRoutes = () => {
  const userFragment = useAuthenticated().userFragment

  const canViewPricingCalculation = userFragment.permissions?.viewPricingCalculation === true
  const canViewTimesheetsForAllUsers = userFragment.permissions?.viewTimesheets === true

  const webAppRoutes = (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/sso/*" element={<SSORoutes />} />
        <Route path="/attributes/*" element={<AttributeRoutes />} />
        <Route path="/activities/*" element={<ActivityRoutes />} />
        <Route path="/appointments/*" element={<AppointmentRoutes />} />
        <Route path="/booking/config/*" element={<BookingConfigRoutes />} />
        <Route path="/budgets/*" element={<BudgetRoutes />} />
        <Route path="/call-centre/*" element={<CallCentreRoutes />} />
        <Route path="/changelog/*" element={<ChangelogRoutes />} />
        <Route path="/comments/*" element={<CommentRoutes />} />
        <Route path="/complaints/*" element={<ComplaintRoutes />} />
        <Route path="/contacts/*" element={<ContactRoutes />} />
        <Route path="/costs/*" element={<CostRoutes />} />
        <Route path="/customers/*" element={<CustomerRoutes />} />
        <Route path="/discounts/*" element={<DiscountRoutes />} />
        <Route path="/dispatch/*" element={<DispatchRoutes />} />
        <Route path="/docs/*" element={<DocumentationRoutes />} />
        <Route path="/downloads/*" element={<DownloadRoutes />} />
        <Route path="/enquiries/*" element={<EnquiryRoutes />} />
        <Route path="/exports/*" element={<ExportRoutes />} />
        <Route path="/finance/*" element={<FinanceRoutes />} />
        <Route path="/glossary/*" element={<GlossaryRoutes />} />
        <Route path="/health/*" element={<HealthRoutes />} />
        <Route path="/inspections/*" element={<InspectionRoutes />} />
        <Route path="/interaction-message/*" element={<InteractionMessageRoutes />} />
        <Route path="/invoices/*" element={<InvoiceRoutes />} />
        <Route path="/locations/*" element={<CustomerLocationRoutes />} />
        <Route path="/risk-assessments/*" element={<RiskAssessmentRoutes />} />
        <Route path="/items/*" element={<ItemRoutes />} />
        <Route path="/jobs/*" element={<JobRoutes />} />
        <Route path="/job-reports/*" element={<JobReportRoutes />} />
        <Route path="/job-types/*" element={<JobTypeRoutes />} />
        {canViewPricingCalculation && <Route path="/labour-rates/*" element={<LabourRateRoutes />} />}
        <Route path="/leaderboards/*" element={<LeaderboardRoutes />} />
        <Route path="/launch" element={<Navigate replace to="fs://welcome" />} />
        <Route path="/marketing/*" element={<MarketingRoutes />} />
        <Route path="/media/*" element={<MediaRoutes />} />
        <Route path="/memberships/*" element={<MembershipRoutes />} />
        <Route path="/notifications/*" element={<NotificationRoutes />} />
        <Route path="/option-sheet-templates/*" element={<OptionSheetTemplateRoutes />} />
        {canViewPricingCalculation && <Route path="/parts/*" element={<PartRoutes />} />}
        <Route path="/payments/*" element={<PaymentRoutes />} />
        <Route path="/performance/*" element={<PerformanceRoutes />} />
        <Route path="/phonebook/*" element={<PhonebookRoutes />} />
        <Route path="/pricelists/*" element={<PricelistRoutes />} />
        <Route path="/properties/*" element={<PropertyRoutes />} />
        <Route path="/public-holidays/*" element={<PublicHolidayRoutes />} />
        <Route path="/reports/*" element={<ReportRoutes />} />
        <Route path="/reviews/*" element={<ReviewRoutes />} />
        <Route path="/sales/*" element={<SalesEventRoutes />} />
        <Route path="/sales-categories/*" element={<SalesCategoryRoutes />} />
        <Route path="/scheduled-services/*" element={<ScheduledServiceRoutes />} />
        <Route path="/schedule/*" element={<ScheduleRoutes />} />
        <Route path="/schedule-slots/*" element={<ScheduleSlotRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        <Route path="/skills/*" element={<AttributeRoutes />} />
        <Route path="/service-areas/*" element={<ServiceAreaRoutes />} />
        <Route path="/teams/*" element={<TeamRoutes />} />
        {canViewTimesheetsForAllUsers && <Route path="/timesheets/*" element={<TimesheetRoutes />} />}
        <Route path="/transactions/*" element={<TransactionRoutes />} />
        <Route path="/users/*" element={<UserRoutes />} />
        <Route path="/workflow/*" element={<WorkflowRoutes />} />
        <Route path="/workflow-v2/*" element={<WorkflowV2Routes />} />
        <Route path="/tenant-config/*" element={<TenantConfigRoutes />} />
        <Route path="/event/:id" element={<ScheduleEventContainer />} />
      </Routes>
    </Suspense>
  )

  const fieldAppRoutes = (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/jobs/*" element={<JobRoutes />} />
        <Route path="/pricelists/*" element={<PricelistRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        <Route path="/event/:id" element={<ScheduleEventContainer />} />
      </Routes>
    </Suspense>
  )

  const renderView = () => {
    const webAppAccess = userFragment.permissions?.webApp === true
    const fieldAppAccess = userFragment.permissions?.fieldApp === true
    console.log(
      `authenticated-routes: ${
        webAppAccess ? 'webAppRoutes' : fieldAppAccess ? 'fieldAppRoutes' : 'no routes available'
      }`
    )
    if (webAppAccess) {
      return webAppRoutes
    }
    if (fieldAppAccess) {
      return fieldAppRoutes
    }
    return <UnauthorizedError message={'Not authorized.'} />
  }

  return renderView()
}

export default AuthenticatedRoutes
