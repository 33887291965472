import { makeHumanReadable } from '@falloncore/util'
import { JobAssignment } from '@fallonsolutions/job-assignment'
import { BookingScope, JobType, TradeType, WorkflowFragment, WorkflowStateFragment } from '@fallonsolutions/types'
import { JobClassificationActionResult } from '../actions/action-job-classification-types'
import { TopicActionResult } from '../topics/action-topic-model'
import { fromWorkflowV2Outcome } from './workflow-v2-booking-outcome-to-skills-mapping'

//Workflow results to V1 enquiry
export const enquiryBridge = (workflow?: WorkflowFragment): JobClassificationActionResult | undefined => {
  if (!workflow) {
    console.log('no workflow')
    return undefined
  }
  console.log('workflow v2 bridge', workflow)
  const { state } = workflow
  const customerType = state.outcome.customerType
  const jobDescription = state.outcome.notes

  const {
    category,
    tradeType: trade,
    jobType
  } = state.outcome.legacyValues
    ? state.outcome.legacyValues
    : { category: 'General', tradeType: TradeType.None, jobType: JobType.Service }

  const workflowCompleted = !workflow.currentStep //|| workflow.currentStep.step.type === WorkflowStepType.Success //workflow.currentStep?.nextSteps.length === 0

  const isSuccess = workflowCompleted === true && workflow.state.outcome.scope === BookingScope.InScope
  const isFailure = workflowCompleted === true && workflow.state.outcome.scope !== BookingScope.InScope
  console.log('is success', isSuccess)
  console.log('is failure', isFailure)

  const actionCompleted = isSuccess

  // TODO: handle failure (eg. out of scope)

  console.log('action completed', actionCompleted)
  const value =
    actionCompleted === true ? `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${jobType}` : undefined
  const travelFee = state.outcome.travelFee
    ? {
        label: state.outcome.travelFee?.name,
        amount: state.outcome.travelFee.amount.amount
      }
    : undefined
  const tags = state.outcome.tags ?? []

  const assignment: JobAssignment | undefined = getAssignment(workflow.state.outcome)
  const { attributes } = fromWorkflowV2Outcome(trade, state.outcome)
  const topic: TopicActionResult = {
    trade,
    customerType,
    category,
    type: jobType,
    jobDescription,
    value: workflow.state.outcome.topic?.name,
    requirements: {
      attributes
    },
    assignment,
    actionCompleted
  }
  const jobClassificationResult: JobClassificationActionResult = {
    bookingOutcome: workflow.state.outcome,
    category,
    topic,
    customerType,
    trade,
    type: jobType,
    tags,
    travelFee,
    actionCompleted,
    scope: workflow.state.outcome.scope,
    assignment,
    requirements: {
      attributes
    },
    value
  }
  return jobClassificationResult
}

const getAssignment = (outcome: WorkflowStateFragment['outcome']): JobAssignment | undefined => {
  if (!outcome.serviceType?.id || !outcome.topic?.id || !outcome.trade?.id || !outcome.branch?.id) {
    return undefined
  }
  return {
    customerType: outcome.customerType,
    serviceTypeId: outcome.serviceType.id,
    topicId: outcome.topic.id,
    tradeId: outcome.trade.id,
    subtopicId: outcome.subtopic?.id,
    tagIds: outcome.tagIds ?? [],
    priority: outcome.priority
  }
}
