import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import { PlatformIcon } from '@fallonsolutions/types'
import { compact } from 'lodash-es'
import { PlatformIconView } from '../icon/platform-icon'
import '../static/css/list-nav.css'

export interface ListNavOption {
  label: string
  value: string
  platformIcon?: PlatformIcon
  icon?: string
}

export interface ListNavOptionViewProps {
  option: ListNavOption
  isSelected?: boolean
  onClick: (option: ListNavOption) => void
  variant?: 'default' | 'fill'
  fontWeight?: number
  direction?: 'forward' | 'back'
  iconColor?: string
  disabled?: boolean
  loading?: boolean
}

export const ListNavOptionView = ({
  option,
  isSelected,
  onClick,
  disabled,
  loading,
  ...props
}: ListNavOptionViewProps) => {
  const { label, icon, platformIcon } = option

  const direction = props.direction ?? 'forward'
  const variant = props.variant ?? 'default'
  const fontWeight = props.fontWeight ?? 400

  const blue = '#007AFF'
  // const blueHover = '#007AFF'
  const gray = '#727888'

  const iconColor = props.iconColor ?? (isSelected ? blue : gray)
  const chevronColor = isSelected ? blue : gray
  // const borderHoverColor = isSelected ? blueHover : lightGrayHover

  const handleOnClick = (option: ListNavOption) => {
    if (disabled || loading) {
      return
    }
    onClick(option)
  }

  const classNames = compact([
    'list-nav-option',
    isSelected ? 'list-nav-option--selected' : undefined,
    variant === 'fill' ? 'list-nav-option--fill' : undefined,
    disabled ? 'list-nav-option--disabled' : undefined,
    loading ? 'list-nav-option--loading' : undefined
  ])

  return (
    <EuiFlexGroup
      className={classNames.join(' ')}
      gutterSize="s"
      alignItems="center"
      onClick={() => handleOnClick(option)}
      style={{
        fontWeight
      }}
    >
      {direction === 'back' && (
        <EuiFlexItem grow={false}>
          <EuiIcon type="arrowLeft" size="m" color={chevronColor} />
        </EuiFlexItem>
      )}
      {icon ? (
        <EuiFlexItem grow={false}>
          <EuiIcon type={icon} size="l" color={iconColor} />
        </EuiFlexItem>
      ) : platformIcon ? (
        <PlatformIconView icon={option.platformIcon} size="l" color={iconColor} />
      ) : null}
      <EuiFlexItem grow={true}>
        <EuiText
          className={`list-nav-option__label ${icon || platformIcon ? 'list-nav-option__label--with-icon' : ''}`}
          size="m"
        >
          {label}
        </EuiText>
      </EuiFlexItem>
      {direction === 'forward' && !disabled && (
        <EuiFlexItem grow={false}>
          <EuiIcon type="arrowRight" size="m" color={chevronColor} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
