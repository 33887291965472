import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { useNavigate } from 'react-router'
import { InteractionMessageLinkFragment } from '../api/generated-types'
import '../static/css/recent-interaction-message-list.css'
import { InteractionMessageCard } from './interaction-message-card'

export interface RecentInteractionMessageListProps {
  interactionMessages: InteractionMessageLinkFragment[]
  expanded: boolean
}

export const RecentInteractionMessageList = ({ interactionMessages, expanded }: RecentInteractionMessageListProps) => {
  const navigate = useNavigate()

  return (
    <div
      className={`recent-interaction-message-list recent-interaction-message-list--${expanded ? 'expanded' : 'scroll'}`}
    >
      <EuiFlexGroup gutterSize="m" alignItems="flexStart" justifyContent="flexStart" wrap={expanded}>
        {interactionMessages.map((interactionMessage) => (
          <EuiFlexItem
            key={interactionMessage.id}
            grow={false}
            onClick={() => navigate(`/interaction-message/${interactionMessage.id}`)}
          >
            <InteractionMessageCard interactionMessage={interactionMessage} />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </div>
  )
}
