import {
  EuiBreadcrumbs,
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPopover,
  EuiProgress
} from '@elastic/eui'
import { WorkflowStepResultDetailFragment } from '@fallonsolutions/types'
import { useState } from 'react'
import { WorkflowFragment } from '../api/generated-types'
import VerticalDivider from '../common/vertical-divider'

export interface WorkflowV2ViewHeaderProps {
  workflow: WorkflowFragment
  loading?: boolean
  onBack: (prevStep?: WorkflowStepResultDetailFragment) => void
  onReset: () => void
}

export const WorkflowV2Header = ({ loading, workflow, onBack, onReset }: WorkflowV2ViewHeaderProps) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const isFirstStep = workflow.steps.length <= 1

  const slicedSteps = workflow.steps.slice(0, -1)

  const breadcrumbs = slicedSteps.length
    ? slicedSteps.map((step) => ({
        text: step.choice?.label ?? step.step.name
      }))
    : [{ text: 'Workflow' }]

  const closeContextMenu = () => setIsContextMenuOpen(false)

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: 'Reset workflow',
          icon: 'refresh',
          onClick: () => {
            onReset()
            closeContextMenu()
          }
        }
      ]
    }
  ]

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '-17px',
            left: '-17px',
            right: '-17px',
            overflow: 'hidden',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px',
            height: '6px'
          }}
        >
          <EuiProgress size="xs" color="primary" />
        </div>
      )}

      <EuiFlexGroup alignItems="center" gutterSize="m">
        <EuiFlexItem grow={false} style={{ minWidth: '25px' }}>
          {loading ? (
            <EuiLoadingSpinner size="m" style={{ marginLeft: '5px' }} />
          ) : !isFirstStep ? (
            <EuiButtonIcon
              aria-label="Back"
              iconType="arrowLeft"
              onClick={() => onBack(workflow.currentStep?.prevStep ?? undefined)}
              isDisabled={loading}
              color="text"
              display="empty"
            />
          ) : (
            <EuiIcon type="home" color="subdued" style={{ marginLeft: '5px' }} />
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiBreadcrumbs max={4} breadcrumbs={breadcrumbs} aria-label="Workflow steps" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            isOpen={isContextMenuOpen}
            button={
              <EuiButtonIcon
                aria-label="Show actions"
                iconType="boxesHorizontal"
                color="text"
                onClick={() => setIsContextMenuOpen(true)}
              />
            }
            closePopover={() => setIsContextMenuOpen(false)}
            ownFocus={true}
            panelPaddingSize="none"
          >
            <EuiContextMenu initialPanelId={0} panels={contextMenuPanels} />
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
