import { EuiFieldText, EuiFormRow, EuiSpacer, EuiTextArea } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { QuestionFieldProps } from './question-field-props'

export interface FreeTextQuestionFieldProps extends QuestionFieldProps<string> {
  rows?: number
  helpText?: string
  loading?: boolean
  includeSpacer?: boolean
}

export const FreeTextQuestionField = (props: FreeTextQuestionFieldProps) => {
  const { question, questionType, hint, answer, changeAnswer, helpText, loading } = props

  const includeSpacer = props.includeSpacer ?? true
  const rows = props.rows ?? 1
  const showQuestion = props.showQuestion ?? true

  return (
    <>
      {showQuestion && (
        <Callout type={questionType ?? 'script'} data-test-id="workflow-question">
          {question}
        </Callout>
      )}
      {hint && (
        <Callout type="note" data-test-id="workflow-question-hint">
          {hint}
        </Callout>
      )}
      {rows === 1 && (
        <EuiFormRow label={undefined} helpText={helpText}>
          <EuiFieldText
            value={answer ?? ''}
            onChange={(e) => changeAnswer(e.target.value)}
            data-test-id="workflow-answer-container"
            isLoading={loading}
            disabled={loading}
          />
        </EuiFormRow>
      )}
      {rows > 1 && (
        <EuiFormRow label={undefined} helpText={helpText} fullWidth>
          <EuiTextArea
            value={answer ?? ''}
            onChange={(e) => changeAnswer(e.target.value)}
            rows={rows}
            data-test-id="workflow-answer-container"
            fullWidth
            isLoading={loading}
            disabled={loading}
          />
        </EuiFormRow>
      )}
      {includeSpacer && <EuiSpacer />}
    </>
  )
}
