import { EuiSpacer } from '@elastic/eui'
import {
  SingleChoiceSubmitWorkflowStepDetailInput,
  SingleChoiceWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type SingleChoiceWorkflowV2StepProps = WorkflowV2StepProps<
  SingleChoiceWorkflowStepDetailFragment,
  SingleChoiceSubmitWorkflowStepDetailInput
>

export const SingleChoiceWorkflowV2Step = ({ result, detail, onChange, loading }: SingleChoiceWorkflowV2StepProps) => {
  const choiceId = result.choiceId

  const options: ListNavOption[] = detail.choices.map((choice) => ({
    value: choice.id,
    label: choice.label,
    icon: choice.iconUrl,
    platformIcon: choice.icon
  }))
  const selected = options.find((o) => o.value === choiceId)

  return (
    <>
      <Callout type="script">{detail.question}</Callout>
      <EuiSpacer size="s" />
      <ListNav
        value={selected}
        options={options}
        onChange={(option) => onChange({ choiceId: option.value })}
        loading={loading}
      />
    </>
  )
}
