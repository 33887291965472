import { PriorityType } from '@fallonsolutions/types'

export interface PriorityIconProps {
  priority: PriorityType
}

export const PriorityIcon = ({ priority }: PriorityIconProps) => {
  const color = getColor(priority)
  return (
    <div
      style={{
        borderRadius: '4px',
        width: '24px',
        height: '21px',
        color: '#ffffff',
        fontWeight: 600,
        fontSize: '11px',
        lineHeight: '21px',
        backgroundColor: color,
        textAlign: 'center'
      }}
    >
      {priority}
    </div>
  )
}

const getColor = (priority: PriorityType) => {
  switch (priority) {
    case PriorityType.P1:
      return '#FF705C'
    case PriorityType.P2:
      return '#FFBB37'
    case PriorityType.P3:
      return '#73C7EB'
    case PriorityType.P4:
      return '#D3DDE2'
  }
}
