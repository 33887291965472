import { useQuery } from '@apollo/client'
import { UserAuthFragment } from '@fallonsolutions/types'
import { useParams } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  EnquiryWebFormFragment,
  GetContactDocument,
  GetCustomerLocationDocument,
  GetEnquiryDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { useFastBooking } from '../common/persisted-state'
import { NewEnquiryWorkflow } from './workflow-new-enquiry'

export const canUseFastBooking = (userFragment: UserAuthFragment | undefined) =>
  userFragment?.permissions?.createBookingManual === true

export const campaignIdParam = 'campaignId'
export const contactIdParam = 'contactId'
export const customerLocationIdParam = 'customerLocationId'
export const jobIdParam = 'jobId'
export const webFormIdParam = 'webFormId'

interface NewEnquiryWorkflowRouteParams extends Record<string, string | undefined> {
  id: string
  customerId?: string
}

export const NewEnquiryWorkflowWrapper = () => {
  const [contactId] = useQueryParam(contactIdParam, StringParam, { updateType: 'replaceIn' })
  const [linkedJobId] = useQueryParam(jobIdParam, StringParam, { updateType: 'replaceIn' })
  const [customerLocationId] = useQueryParam(customerLocationIdParam, StringParam, { updateType: 'replaceIn' })
  const [fastBookingScriptEnabled, setFastBookingScriptEnabled] = useFastBooking(true)

  const { id: enquiryId, customerId } = useParams<NewEnquiryWorkflowRouteParams>()

  if (!enquiryId) {
    return null
  }

  const userFragment = useAuthenticated().userFragment
  const { data: getContact, loading: contactLoading } = useQuery(GetContactDocument, {
    skip: !contactId,
    variables: { input: { contactId: contactId ?? '' } },
    fetchPolicy: 'cache-and-network'
  })

  const { data } = useQuery(GetEnquiryDocument, {
    skip: !enquiryId,
    variables: { input: { id: enquiryId } },
    fetchPolicy: 'cache-first'
  })

  const enquiry = data?.getEnquiry?.enquiry
  const webForm: EnquiryWebFormFragment | undefined | null = enquiry?.webForm

  const { data: getCustomerLocation, loading: customerLocationLoading } = useQuery(GetCustomerLocationDocument, {
    variables: {
      input: {
        id: customerLocationId ?? ''
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !customerLocationId
  })

  // TODO: CSR can skip the queue as well only if it isn't an interaction message
  const canUserUseFastBooking = canUseFastBooking(userFragment)
  const fastTrackBookingEnabled = canUserUseFastBooking && fastBookingScriptEnabled
  const toggleFastTrackBookingState = canUserUseFastBooking

  const contact = getContact?.getContact?.contact ?? undefined
  const customerLocation = getCustomerLocation?.getCustomerLocation?.customerLocation ?? undefined
  const loading = contactLoading || customerLocationLoading

  return (
    <>
      {!loading && (
        <NewEnquiryWorkflow
          customerLocation={customerLocation}
          contact={contact}
          existingJob={linkedJobId ?? undefined}
          fastTrackBookingEnabled={fastTrackBookingEnabled}
          editFastTrackBookingState={toggleFastTrackBookingState}
          setFastBookingScript={setFastBookingScriptEnabled}
          customerId={customerId}
          enquiryId={enquiryId}
          webForm={webForm}
        />
      )}
    </>
  )
}
