import { AwsCredentialIdentity } from '@aws-sdk/types'
import { Amplify } from 'aws-amplify'
import { AuthError, AuthTokens, fetchAuthSession, getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useApp } from '../app/app-context'
import { LoadingSpinner } from '../loading/loading-spinner'
import { SessionState } from './session'
import { ISessionContext, NewSessionState, SessionContext, SessionLoaderContext } from './session-context'

interface AuthenticationProps {
  children: React.ReactNode
}

// TODO: import from aws-amplify/auth/src/types somehow
const USER_UNAUTHENTICATED_EXCEPTION = 'UserUnAuthenticatedException'
const USER_ALREADY_AUTHENTICATED_EXCEPTION = 'UserAlreadyAuthenticatedException'
const USER_ID_FIELD = 'fsp:user_id'
const TENANT_ID_FIELD = 'fsp:tenant_id'
const USERNAME_FIELD = 'cognito:username'

const AuthenticationProvider = (props: AuthenticationProps) => {
  const navigate = useNavigate()
  const appContext = useApp()
  const [sessionState, setSessionState] = useState<NewSessionState>({})
  const [loading, setLoading] = useState(true)

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: appContext.userPoolId,
        userPoolClientId: appContext.cognitoClientId,
        identityPoolId: appContext.identityPoolId,
        loginWith: {
          oauth: {
            domain: appContext.authDomain,
            scopes: appContext.tokenScopes,
            redirectSignIn: [`https://${appContext.authCallbackUri}`],
            redirectSignOut: [`https://${appContext.authSignoutUri}`],
            responseType: 'code'
          }
        },
        allowGuestAccess: false
      }
    }
  })

  const sessionContext: ISessionContext = {
    ...sessionState,
    signIn: () => {
      console.log('authentication-provider: signIn')
      signInWithRedirect()
    },
    signOut: async () => {
      console.log('authentication-provider: signOut')
      await signOut({
        global: false,
        oauth: {
          redirectUrl: `https://${appContext.authSignoutUri}`
        }
      })
    },
    handleCallback: async (_url: string, redirectUrl?: string) => {
      console.log('authentication-provider: authCallback')
      await createSession(false)
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true })
      }
    },
    refresh: async () => {
      console.log('authentication-provider: refreshing auth')
      const newSession = await createSession(true)
      return newSession?.user ?? undefined
    }
  }

  const createSession = async (forceRefresh: boolean = false): Promise<NewSessionState | undefined> => {
    console.log('authentication-provider: createSession')
    console.time('authentication-provider: createSession')
    setLoading(true)
    try {
      const [session, authUser] = await Promise.all([fetchAuthSession({ forceRefresh }), getCurrentUser()])
      const tokens = session?.tokens
      const credentials = session?.credentials
      const payload = tokens?.idToken?.payload
      if (!authUser || !tokens || !payload || !credentials) {
        setSessionState({})
        setLoading(false)
        return undefined
      }
      const user = authTokensToSessionState(credentials, tokens)
      const newSession = { user, expiry: user.expiry }
      console.log('authentication-provider: createSession session', newSession.user.email, newSession.user.tenantId)
      setSessionState(newSession)
      await fetchMediaAuth(newSession.user.token)
      setLoading(false)
      console.timeEnd('authentication-provider: createSession')
      return newSession
    } catch (err) {
      if (err instanceof AuthError) {
        switch (err.name) {
          case USER_UNAUTHENTICATED_EXCEPTION:
            console.log('authentication-provider: createSession user not signed in')
            break
          case USER_ALREADY_AUTHENTICATED_EXCEPTION:
            console.log('authentication-provider: createSession user already signed in, force refresh')
            createSession(true)
            break
          default:
            console.error('authentication-provider: createSession error', err.name, err.message)
        }
      } else {
        console.error('authentication-provider: createSession unknown error', err)
      }
      setSessionState({})
      setLoading(false)
      console.timeEnd('authentication-provider: createSession')
      return undefined
    }
  }

  const fetchMediaAuth = async (idToken: string) => {
    console.log('authentication-provider: media authorization 3rd party call')
    const response = await fetch(`https://${appContext.mediaDomain}/token`, {
      mode: 'cors',
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include', //browser set cookie cross origin https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#differences_from_jquery
      headers: [['media_token', idToken]]
    })
    console.log('authentication-provider: media authorization 3rd party response', response.status)
  }

  const init = async () => {
    console.log('authentication-provider: init')
    await createSession()
  }

  useEffect(() => {
    init()
  }, [])

  const session: SessionLoaderContext = {
    loading,
    loaded: sessionContext.user?.signedIn === true,
    error: '',
    data: sessionContext,
    retry: sessionContext.refresh
  }

  return loading ? (
    <LoadingSpinner message="Authenticating" />
  ) : (
    <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>
  )
}

const authTokensToSessionState = (credentials: AwsCredentialIdentity, tokens: AuthTokens): SessionState => {
  const payload = tokens.idToken?.payload
  if (!payload) {
    throw new Error('No payload found in token')
  }
  const exp = payload?.exp ?? new Date().getUTCSeconds()
  return {
    signedIn: true,
    id: payload[USER_ID_FIELD] as string,
    tenantId: payload[TENANT_ID_FIELD] as string,
    username: payload[USERNAME_FIELD] as string,
    firstName: payload.given_name as string,
    lastName: payload.family_name as string,
    fullName: compact([payload.given_name as string, payload.family_name as string]).join(' '),
    email: payload.email as string,
    avatar: payload?.picture as string | undefined,
    token: tokens.idToken?.toString() ?? '',
    accessToken: tokens.accessToken.toString(),
    expiry: DateTime.fromSeconds(exp).toISO(),
    expiryEpoch: exp,
    tokens,
    credentials
  }
}

export default AuthenticationProvider
