import { TextSubmitWorkflowStepDetailInput, TextWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { FreeTextQuestionField } from '../../workflow/question-fields/free-text-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type TextWorkflowV2StepProps = WorkflowV2StepProps<
  TextWorkflowStepDetailFragment,
  TextSubmitWorkflowStepDetailInput
>

export const TextWorkflowV2Step = ({ result, detail, onChange, loading }: TextWorkflowV2StepProps) => {
  const onChangeAnswer = (answer: string | undefined) => {
    onChange({ value: answer ?? '' })
  }

  const value = result.value ?? ''

  return (
    <FreeTextQuestionField
      rows={detail.rows ?? undefined}
      question={detail.question}
      answer={value}
      changeAnswer={onChangeAnswer}
      loading={loading}
      includeSpacer={false}
    />
  )
}
