import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText, IconType } from '@elastic/eui'
import { useState } from 'react'
import '../static/css/callout.css'

export type CalloutType = 'script' | 'note' | 'warning' | 'help' | 'success' | 'failure'

export type CalloutFontSize = 's' | 'm' | 'l'

// TODO:
// react-testing-library use data-test-id and so far with cypress we are using data-test-id
// can we consolidate on use of one of these? Not sure if we can override selector for
// react-testing-library

const colorsForType = (type: CalloutType): string[] => {
  switch (type) {
    case 'script':
      return ['#00FFD1', '#F7F8FC']
    case 'warning':
      return ['#FFE350', '#FFFBE9']
    case 'help':
      return ['#0D76C9', '#ECF7FF']
    case 'success':
      return ['#24d21f', '#e8f9e6']
    case 'failure':
      return ['#FF8970', '#FFEBE7']
    case 'note':
      return ['#BBB', '#F3F3F3']
    default:
      return ['#80889E', '#F7F8FC']
  }
}

const iconForType = (type: CalloutType): string => {
  switch (type) {
    case 'script':
      return 'editorComment'
    case 'warning':
      return 'alert'
    case 'help':
      return 'help'
    case 'success':
      return 'check'
    case 'failure':
      return 'cross'
    case 'note':
    default:
      return 'iInCircle'
  }
}

const getFontSize = (fontSize: CalloutFontSize): string => {
  switch (fontSize) {
    case 's':
      return '16px'
    case 'l':
      return '20px'
    case 'm':
    default:
      return '18px'
  }
}

export interface CalloutProps {
  title?: string | undefined
  iconType?: IconType | undefined
  type?: CalloutType | undefined
  fontSize?: CalloutFontSize | undefined
  className?: string | undefined
  'data-test-id'?: string
  children?: React.ReactNode
}

export const Callout = (props: CalloutProps) => {
  const { title, className } = props

  const type = props.type ?? 'note'
  const fontSize = getFontSize(props.fontSize ?? 's')

  const [expanded, setExpanded] = useState(type !== 'help')

  const iconType = props.iconType ?? iconForType(type)
  const [borderLeftColor, backgroundColor] = colorsForType(type)

  return (
    <div style={{ marginBottom: '3px' }} data-test-id={props['data-test-id']}>
      <EuiFlexGroup
        gutterSize="m"
        className={`callout ${className ?? ''}`}
        style={{ borderLeftColor, backgroundColor }}
      >
        <EuiFlexItem grow={false}>
          <EuiIcon type={iconType} size="l" color="subdued" />
        </EuiFlexItem>
        <EuiFlexItem style={{ fontSize, paddingTop: '2px' }}>
          {title && (
            <EuiText size="relative" style={title && props.children ? { fontWeight: 600 } : {}}>
              {title}{' '}
            </EuiText>
          )}
          {expanded && (
            <>
              {title && props.children && <EuiSpacer size="m" />}
              {props.children}
            </>
          )}
        </EuiFlexItem>
        {type === 'help' && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              aria-label="Toggle help"
              iconType={expanded ? 'arrowDown' : 'arrowRight'}
              onClick={() => setExpanded(!expanded)}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  )
}
