import { useEffect } from 'react'
import PageHeading from '../common/page-heading'
import { useAuthorization } from './authorization-context'
import { useSession } from './session-context'

const Signout = () => {
  const session = useSession()
  const authorization = useAuthorization()

  useEffect(() => {
    if (authorization?.data?.iamUtils) {
      authorization?.data?.iamUtils?.signOut()
    }
    if (session.data?.user) {
      session.data.signOut()
    } else {
      window.location.href = '/sso/signout'
    }
  }, [])

  return (
    <div>
      <PageHeading title="Signing out..." />
    </div>
  )
}

export default Signout
