import { EuiIcon } from '@elastic/eui'
import { PlatformIcon } from '@fallonsolutions/types'
import { platformIconToEuiIcon } from './platform-icon-to-eui-icon'

export interface PlatformIconProps {
  icon?: PlatformIcon
  size?: 's' | 'm' | 'l' | 'xl'
  color?: string
}

export const PlatformIconView = ({ icon, color, ...props }: PlatformIconProps) => {
  const euiIconType = platformIconToEuiIcon(icon ?? PlatformIcon.Empty)
  const size = props.size ?? 'm'
  return (
    <>
      <EuiIcon type={euiIconType} size={size} color={color} />
    </>
  )
}
