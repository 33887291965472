import { DocumentNode, useQuery } from '@apollo/client'
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonGroup,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiTableSelectionType,
  EuiTitle
} from '@elastic/eui'
import { isNil } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { MutableRefObject, ReactNode, useEffect, useState } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import PaginatedTable, { Pagination, Sort } from '../common/paginated-table'
import { PaginatedCardView } from './paginated-card-view'

export interface ListContainerRouteParams extends Record<string, string | undefined> {
  index: string
}

export interface ListContainerData<T extends object> {
  rows: T[]
  columns?: Array<EuiBasicTableColumn<T>> // Override the default columns based on result - useful for things like date columns
  total: number
  summary?: any
  dateHistogram?: any
}

interface ListContainerProps<T extends object> {
  variables?: any
  query: DocumentNode
  pollInterval?: number
  title?: string
  modelName: string
  filters?: ReactNode
  actions?: ReactNode
  footer?: ReactNode
  input?: any
  options?: any
  alternativeVariableKey?: string // for when the structure of the variable doesn't follow variables.input
  columns: Array<EuiBasicTableColumn<any>>
  extractData: (data: any) => ListContainerData<T>
  metrics?: (summary: any) => ReactNode
  chart?: (start: Moment, end: Moment, data: any) => ReactNode
  rightBarItem?: ReactNode
  defaultPageSize?: number
  paginationOnInput?: boolean
  onChangeResultTotal?: (count: number) => void
  renderCardView?: (row: T) => ReactNode
  selection?: EuiTableSelectionType<T>
  itemId?: string | number | ((item: T) => string)
  tableRef?: MutableRefObject<EuiBasicTable | null>
  className?: string
  inMemoryTable?: boolean
  defaultSorting?: { field: string; direction: 'asc' | 'desc' }
  paginationOnVariables?: boolean
  tableClassName?: string
}

enum ListContainerViewType {
  Table = 'table',
  Card = 'card'
}

// eslint-disable-next-line complexity
const ListContainer = <T extends object>(props: ListContainerProps<T>) => {
  const {
    query,
    pollInterval,
    modelName,
    filters,
    input,
    actions,
    options,
    columns,
    extractData,
    metrics,
    chart,
    rightBarItem,
    onChangeResultTotal,
    footer,
    paginationOnInput,
    renderCardView,
    selection,
    itemId,
    tableRef,
    className,
    inMemoryTable,
    defaultSorting,
    alternativeVariableKey,
    paginationOnVariables,
    tableClassName
  } = props

  const defaultPageSize = props.defaultPageSize ?? 20
  const title = props.title ?? null

  const [viewType, setViewType] = useState(ListContainerViewType.Table)
  useEffect(() => {
    if (title) {
      document.title = title
    }
  })

  const [queryParams, setQueryParams] = useQueryParams(
    {
      index: withDefault(NumberParam, 0),
      size: withDefault(NumberParam, defaultPageSize ?? 20),
      ...(inMemoryTable && {
        sortField: withDefault(StringParam, defaultSorting?.field ?? ''),
        sortDirection: withDefault(StringParam, defaultSorting?.direction ?? '')
      })
    },
    { updateType: 'replaceIn' }
  )
  const size = queryParams.size ?? defaultPageSize
  const from = (queryParams?.index ?? 0) * size

  const variables: any = props.variables ?? (!paginationOnInput ? { from, size } : {})

  if (input) {
    variables.input = {
      ...input,
      ...(paginationOnInput ? { from, size } : {})
    }
    if (alternativeVariableKey) {
      variables[alternativeVariableKey] = input
      variables.from = from
      variables.size = size
      delete variables.input
    }
  }
  if (paginationOnVariables) {
    variables.from = from
    variables.size = size
  }
  if (options) {
    variables.options = options
  }
  const { loading, error, data } = useQuery(query, {
    variables,
    pollInterval
  })
  const tableData = data ? extractData(data) : undefined

  useEffect(() => {
    if (onChangeResultTotal && !!tableData?.total) {
      onChangeResultTotal(tableData.total)
    }
  }, [onChangeResultTotal, tableData?.total])

  const onChangePagination = (event: Pagination) => {
    const { size } = event
    let index = event.index
    if (!isNil(queryParams.size) && size !== queryParams.size) {
      index = 0
    }
    setQueryParams((p) => ({ ...p, index, size }))
  }

  const onChangeSort = (props: Sort) => {
    const { field, direction } = props ?? {}
    console.log('field', field)
    console.log('direction', direction)
    //setQueryParams((p) => ({ ...p, sortField: field, sortDirection: direction }))
  }

  // Change in filters should reset pagination to page 0
  useEffect(() => {
    setQueryParams((p) => ({ ...p, index: 0, size: queryParams.size }))
  }, [input, queryParams.size, setQueryParams])

  const titleView = (
    <EuiFlexGroup alignItems="center">
      <EuiFlexItem grow={true}>
        {title && (
          <EuiTitle size="l">
            <h1>{title}</h1>
          </EuiTitle>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{rightBarItem}</EuiFlexItem>
    </EuiFlexGroup>
  )

  const errorView = <EuiCallOut color="danger">Encountered error: {error?.message}</EuiCallOut>

  const currentPagination: Pagination = {
    index: queryParams.index ?? 0,
    size: queryParams.size ?? defaultPageSize
  }

  const currentSorting: Sort = {
    field: queryParams.sortField ?? defaultSorting?.field ?? '',
    direction: (queryParams.sortDirection ?? defaultSorting?.direction ?? 'desc') as Sort['direction']
  }

  const dataView =
    loading && !tableData ? (
      <EuiLoadingSpinner size="l" />
    ) : tableData ? (
      <div>
        {metrics && tableData.summary && (
          <div>
            {metrics(tableData.summary)}
            <EuiSpacer />
          </div>
        )}
        {chart && <div>{chart(moment(), moment(), tableData)}</div>}

        {!!renderCardView && (
          <>
            <EuiFlexGroup>
              {!!renderCardView && (
                <EuiFlexItem grow={false}>
                  <EuiButtonGroup
                    idSelected={viewType}
                    options={[
                      { id: ListContainerViewType.Table, label: 'Table', iconType: 'tableOfContents' },
                      { id: ListContainerViewType.Card, label: 'Card', iconType: 'grid' }
                    ]}
                    onChange={(id: string) => setViewType(id as ListContainerViewType)}
                    legend="View type"
                    color="primary"
                    buttonSize="compressed"
                    isIconOnly
                  />
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={true} />
            </EuiFlexGroup>
            <EuiSpacer size="s" />
          </>
        )}

        {viewType === ListContainerViewType.Card && !!renderCardView ? (
          <PaginatedCardView rows={tableData.rows} renderCardView={renderCardView} />
        ) : (
          <PaginatedTable
            tableRef={tableRef}
            selection={selection}
            itemId={itemId}
            modelName={modelName}
            total={tableData.total}
            rows={tableData.rows}
            columns={tableData.columns ?? columns}
            pagination={currentPagination}
            sorting={currentSorting}
            onChangePagination={onChangePagination}
            onChangeSort={onChangeSort}
            actions={actions}
            footer={footer}
            inMemoryTable={inMemoryTable}
            tableClassName={tableClassName}
          />
        )}
      </div>
    ) : null

  return (
    <div className={className}>
      {(title || rightBarItem) && (
        <div>
          {titleView}
          <EuiSpacer />
        </div>
      )}
      {filters}
      <EuiSpacer />
      {error ? errorView : dataView}
    </div>
  )
}

export default ListContainer
