import { OutOfScopeSubmitWorkflowStepDetailInput, OutOfScopeWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export type OutOfScopeWorkflowV2StepProps = WorkflowV2StepProps<
  OutOfScopeWorkflowStepDetailFragment,
  OutOfScopeSubmitWorkflowStepDetailInput
>

export const OutOfScopeWorkflowV2Step = ({ detail }: OutOfScopeWorkflowV2StepProps) => {
  const { title, description } = detail
  return (
    <Callout title={title} type="warning">
      {description}
    </Callout>
  )
}
